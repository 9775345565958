import React from 'react';
import { Link } from 'gatsby';
import ContentPageWrapper from '../containers/Charity/ChapterGeneral/contentPageWrapper';
import SEO from '../components/seo';
import imageChapter16 from '../common/src/assets/image/charity/specter/kapitola_16_small.jpg';

export default () => {
  return (
    <ContentPageWrapper>
      <SEO title="Šestnásta kapitola: Komunizmus v pozadí environmentalizmu | Ako duch komunizmu vládne nášmu svetu"
           description="Šestnásta kapitola: Komunizmus v pozadí environmentalizmu"
           image={imageChapter16}/>
        <h1>Šestnásta kapitola: Komunizmus v pozadí environmentalizmu</h1>
        <p>Stvoriteľ poskytuje podmienky pre ľudský život a pre všetky veci na zemi. Tradičné kultúry zdôrazňujú priaznivý, symbiotický vzťah medzi človekom a prírodou. Staroveký čínsky filozof Tung Čung-šu v diele <em>Nádherná rosa jarných a jesenných letopisov</em> napísal: „Všetko na zemi bolo stvorené pre prospech človeka.“<a href="#_edn1" id="_ednref1">[1]</a> ​​Zároveň si ľudia musia zachovávať úctu k prírode tým, že budú nasledovať princípy neba a zeme.</p>
        <p>V tradičnej čínskej filozofii sa hovorí o rovnováhe medzi všetkými vecami a o nutnosti neubližovať. V Konfuciovej <em>Ceste stredu</em> sa dočítame: „Morálne zákony tvoria jeden systém so zákonmi, podľa ktorých nebo a zem podporujú a obsahujú, zatieňujú a&nbsp;prekrývajú všetky veci. ... Je to ten istý systém zákonov, pomocou ktorého sa tvoria a vyvíjajú všetky stvorené veci. Je to systém, kde jeden druhého nezraňuje a kde sa procesy prírody odohrávajú bez konfliktu či zmätku.“<a href="#_edn2" id="_ednref2">[2]</a> Starí Číňania si preto vážili ochranu životného prostredia. Ako uvádzajú legendárne pramene, za čias cisára Jüa Veľkého zhruba pred štyrmi tisíckami rokov „ľudia v troch jarných mesiacoch nebrali do lesa sekery, aby mohol les prekvitať. Počas troch letných mesiacov ľudia nepokladali do riek siete, aby sa ryby mohli rozmnožovať“.<a href="#_edn3" id="_ednref3">[3]</a> Konfuciov žiak Ceng-c’ napísal: „Drevo by sa malo ťažiť iba v&nbsp;správnych ročných obdobiach a zvieratá by sa mali porážať len v správnom čase.“<a href="#_edn4" id="_ednref4">[4]</a></p>
        <p>V týchto vetách sa odrážajú tradičné predstavy o&nbsp;umiernenom jednaní a o rešpektovaní a ochrane životného prostredia, s ktorými sa stretneme nielen v Číne, ale aj v starodávnych kultúrach po celom svete.</p>
        <p>Od priemyselnej revolúcie si je spoločnosť viac vedomá vážnej ekologickej škody spôsobenej znečistením. Na Západe sa tieto škody začali čiastočne kompenzovať prijatím zákonov a&nbsp;predpisov na ochranu životného prostredia. V ekonomicky vyspelých krajinách je dôležitosť ochrany životného prostredia všeobecne uznávaná.</p>
        <p>Už menej známe však je, ako boli prevládajúce environmentálne naratívy v dnešnej spoločnosti formované a&nbsp;manipulované komunizmom. Hoci dôvody pre ochranu životného prostredia sú legitímne a mnoho ľudí má skutočnú túžbu zlepšovať prostredie a chrániť budúcu prosperitu ľudstva, komunistické prvky viedli veľkú časť environmentálneho hnutia k presadeniu svojej vlastnej politickej agendy. Komunistická infiltrácia environmentalizmu prebieha prakticky už od vzniku environmentálneho hnutia.</p>
        <p>Veda o životnom prostredí je zložitý odbor štúdia a v&nbsp;jej výskume nenájdeme žiadne pevné závery týkajúce sa tém ako je zmena klímy. Napriek tomu pod vplyvom ľavicovej ideológie veľa ekologických („zelených“) aktivistov a organizácií zjednodušilo a premenilo ochranu životného prostredia na vysoko spolitizovaný boj, často za použitia extrémnych metód a radikálnych naratívov, niekedy až s takmer náboženským zanietením. Namiesto toho, aby sa riadili starodávnymi učeniami o umiernenosti a&nbsp;o ochrane prostredia, radikálni ľavicoví environmentalisti&nbsp; sa vyhýbajú morálke a tradícii a vo svojej krížovej výprave bojujú proti všetkému, čo považujú za „nepriateľa“ environmentalizmu, od súkromného podnikania až po plodenie detí. V kombinácii s ďalšími radikálnymi hnutiami začala byť kampaň na ochranu životného prostredia definovaná prostredníctvom zavádzajúcej propagandy a autoritárskych politických opatrení, kvôli ktorým sa z environmentalizmu stal v podstate akýsi odľahčený druh komunizmu.</p>
        <p>V tejto kapitole sa zameriame na to, ako sa environmentalizmus ako ideológia zlúčil s komunizmom a ako bolo environmentálne hnutie komunizmom unesené, zmanipulované a&nbsp;využité na to, aby pomohlo naplniť komunistické ciele.</p>
        <h2>1. Komunizmus a environmentálne hnutie</h2>
        <p>Po rozpade Sovietskeho zväzu a východoeurópskeho komunistického bloku pokračovali komunisti v šírení svojho vplyvu ako vo východnej, tak aj v západnej spoločnosti. Spolu s tým sa tiež snažili zriadiť pevne kontrolovanú globálnu vládu.</p>
        <p>Aby to však dosiahli, musia komunisti vytvoriť alebo použiť „nepriateľa“, ktorý ohrozuje celé ľudstvo, a vydesiť verejnosť na celom svete, aby odovzdala svoje osobné slobody aj štátnu suverenitu. Prostriedkom k tomu sa stalo práve vytvorenie globálnej paniky ohľadom hroziacich environmentálnych a ekologických katastrof.</p>
        <h2>a) Tri etapy environmentalizmu</h2>
        <p>Vznik a vývoj environmentálneho hnutia je neoddeliteľne spätý s komunizmom. Jeho vývoj možno rozdeliť do troch etáp.</p>
        <h3>Prvá etapa</h3>
        <p>Prvá etapa bola obdobím teoretického vývoja, ktoré začalo vydaním <em>Komunistického</em> <em>manifestu</em> Karla Marxa a&nbsp;Friedricha Engelsa v roku 1848 a trvalo až do prvého Dňa Zeme v roku 1970.</p>
        <p>Marx a jeho nasledovníci nezameriavali svoj teoretický diskurz na environmentalizmus, avšak marxistický ateizmus a&nbsp;materializmus boli prirodzene v súlade s hlavným trendom súčasného environmentálneho hnutia. Marx vyhlásil, že kapitalizmus je proti prírode (tzn. životnému prostrediu). Marxisti zaviedli pojem „ekosystém“ a potichu naplnili environmentalizmus rôznymi spoločenskými problémami.</p>
        <p>V poslednej dekáde prvej fázy, medzi rokmi 1960 a 1970, dva bestsellery – <em>Mlčiaca jar</em> (Silent Spring, 1962) a&nbsp;<em>Populačná bomba</em> (Population Bomb, 1968) – uviedli environmentalizmus na spoločenskú scénu. Prvá kniha zdvihla legitímnu vlnu záujmu ohľadom škody, ktorú prírodnému prostrediu spôsobujú chemické pesticídy. Druhá kniha obhajovala okamžitú a rozsiahlu kontrolu populácie, čím zasiala semienka pre antihumánne spodné prúdy komunizmom ovplyvneného environmentálneho hnutia.</p>
        <h3>Druhá etapa</h3>
        <p>Zo širšej perspektívy fungovalo hnutie kontrakultúry 60. rokov takmer ako vojenská prehliadka komunistických elementov na Západe. Na scénu sa vyhrnuli najprv pod zástavou občianskych práv a&nbsp;protivojnových hnutí a následne sa rýchlo presunuli do ostatných foriem bitiek proti „systému“, ako je feministické hnutie, sexuálna revolúcia a environmentalizmus. Toto je základ vzostupu environmentálnej ideológie a&nbsp;agitácie.</p>
        <p>Prvý Deň Zeme, ktorý bol vyhlásený v roku 1970, znamenal počiatok druhej etapy. Krátko nato, v roku 1972, usporiadala Organizácia spojených národov v Štokholme svoju prvú Konferenciu o životnom prostredí človeka. Rýchlo sa vytvorila skupina organizácií a monitorovacích skupín. &nbsp;V Spojených štátoch a Európe tieto skupiny tlačili na vlády prostredníctvom protestov, propagandy a aktivizmu pod rúškom vedeckého výskumu, atď.</p>
        <h3>Tretia etapa</h3>
        <p>Tretia etapa začala v samotnom závere studenej vojny, keď sa komunizmus vo východnej Európe ocitol na pokraji politického kolapsu. V&nbsp;tomto čase začali komunisti vyostrovať rétoriku a pretláčať naratív o „záchrane sveta“.</p>
        <p>V roku 1988 Svetová meteorologická organizácia a&nbsp;Environmentálny program OSN ustanovili Medzivládny panel o zmene klímy (IPCC) a do politickej sféry začala vstupovať myšlienka globálneho otepľovania. V roku 1990, niekoľko mesiacov pred kolapsom Sovietskeho zväzu, Moskva usporiadala medzinárodnú konferenciu o&nbsp;životnom prostredí. Generálny tajomník Komunistickej strany Sovietskeho zväzu Michail Gorbačov v prejave obhajoval vytvorenie medzinárodného systému monitorovania životného prostredia a zmluvy na ochranu „jedinečných environmentálnych zón“. Vyjadril tiež podporu environmentálnym programom OSN a nadväzujúcej konferencii, ktorá sa konala v roku 1992 v brazílskom Rio de Janeiru.<a href="#_edn5" id="_ednref5">[5]</a></p>
        <p>Vyzeralo to, že väčšina západných environmentalistov tieto návrhy prijala a začala brať globálne klimatické zmeny spôsobené človekom ako hlavnú hrozbu pre ľudstvo. Propaganda, ktorá používala ochranu životného prostredia ako ospravedlnenie pre tvrdé politické opatrenia, začala prudko eskalovať a rapídne tiež narástol počet environmentálnych zákonov a nariadení.</p>
        <p>Ako bude popísané ďalej v tejto kapitole, z dôvodu politizácie ekologických otázok vytesnila hlasná propaganda a obrovská medializácia rozumné opatrenia a vedecký výskum.</p>
        <h2>b) Marxistické korene environmentálnych hnutí</h2>
        <p>Východná tradícia vníma ľudské bytosti ako dušu všetkej hmoty a považuje ich za jeden z Troch talentov (ktorými sa myslí nebo, zem a ľudské bytosti). Západné náboženstvo učí, že človeka stvoril Boh k svojmu vlastnému obrazu. Preto má ľudský život vyššiu hodnotu, zmysel a dôstojnosť. Príroda má poskytovať ľudstvu výživu a&nbsp;ľudia majú povinnosť vážiť si prírodné prostredie a riadne sa o neho starať.</p>
        <p>V očiach ateistov a materialistov však ľudský život žiadnu zvláštnu hodnotu nemá. Engels napísal v jednej zo svojich esejí: „Život je spôsob existencie proteínových tiel.“<a href="#_edn6" id="_ednref6">[6]</a> Z tohto pohľadu nie je ľudský život ničím viac, než konfiguráciou proteínov, ktorá sa žiadnym zásadným spôsobom nelíši od zvierat či rastlín. Je teda logické, že ľuďom môže byť pod zámienkou ochrany prírody odopieraná sloboda, či dokonca vzatý život.</p>
        <p>V aktualizovanom vydaní svojej knihy o organickej chémii zo 40. rokov 19. storočia, kritizoval nemecký chemik Justus von Liebig britských poľnohospodárov kvôli tomu, že ako hnojivo používali dovážané guáno. Britské poľnohospodárstvo malo z vtáčieho hnoja ako účinného hnojiva prospech a výnosy plodín sa výrazne zvýšili. V polovici 19. storočia tak mali Briti dostatok kvalitných potravín. Von Liebig vymenoval rôzne argumenty proti prílišnej závislosti na dovážanom hnojive. Medzi nimi spomenul aj to, aký vplyv má zber guána na populáciu ostrovných vtákov a poukázal na jeho dlhodobú neudržateľnosť. Protestoval tiež proti dlhšej dĺžke života a&nbsp;početnejším rodinám dobre živeného britského obyvateľstva a tvrdil, že viac&nbsp; ľudí znamená viac škôd na životnom prostredí.<a href="#_edn7" id="_ednref7">[7]</a></p>
        <p>Marx v čase, keď písal svoj <em>Kapitál</em>, starostlivo študoval von Liebigovu prácu a použil jeho argumenty k útoku na kapitalistický systém. Marx chválil Liebigovu prácu za to, že z „hľadiska prírodných vied poukázala na negatívnu, t.&nbsp;j. deštruktívnu stránku moderného poľnohospodárstva“.<a href="#_edn8" id="_ednref8">[8]</a> Marx považoval akúkoľvek snahu o vytvorenie bohatstva využitím prírodných zdrojov za začarovaný kruh. Dospel k záveru, že „racionálne poľnohospodárstvo nie je zlučiteľné s kapitalistickým systémom“.</p>
        <p>Potom čo Lenin a jeho boľševická strana vykonali v roku 1917 v Rusku štátny prevrat, rýchlo schválili Výnos o pôde a&nbsp;Výnos o lesoch, aby znárodnili pôdne, lesné, vodné, minerálne, živočíšne a rastlinné zdroje a zabránili verejnosti v ich využívaní bez povolenia štátu.</p>
        <p>Americký meteorológ a spisovateľ Brian Sussman vo svojej knihe z roku 2012 <em>Eko-tyrania: Ako ľavicová zelená agenda rozloží Ameriku </em>( Eco-Tyranny: How the Left’s Green Agenda Will Dismantle America ) napísal, že Marxove a Leninove myšlienky tvoria základ pre myšlienky dnešných environmentalistov. Podľa ich názoru nemá nikto právo profitovať z prírodných zdrojov. „Nech už zachraňujú lesy, veľryby, slimáky alebo klímu, všetko sa vracia k hlboko zakorenenému presvedčeniu, že snaha takto profitovať je nemorálna, a ak sa nezastaví, nakoniec zničí planétu,“ napísal Sussman.<a href="#_edn9" id="_ednref9">[9]</a></p>
        <p>Do globálneho environmentálneho hnutia sa zapojilo veľké množstvo mysliteľov, politikov, vedcov, spoločenských aktivistov a&nbsp;mediálnych osobností. Táto kniha nemá dostatok priestoru pre výpočet ich myšlienok, prejavov a akcií v plnom rozsahu. Nemožno však ignorovať rolu Maurice Stronga, zakladateľa a prvého výkonného riaditeľa Programu OSN pre životné prostredie.</p>
        <p>Kanaďan Strong takisto organizoval konferencie OSN, vrátane Konferencie o životnom prostredí človeka v roku 1972 a Konferencie o životnom prostredí a rozvoji v roku 1992. Strong bol hlboko ovplyvnený svojou sesternicou Annou Louise Strongovou, známou prokomunistickou novinárkou, ktorá bola pochovaná v Číne. Strong sám seba označoval za „socialistu v ideológii a kapitalistu v metodológii“.<a href="#_edn10" id="_ednref10">[10]</a></p>
        <p>Strong zaujal v globálnom environmentálnom hnutí dôležité miesto. Názory, ktoré úrad OSN pod vedením Stronga zastával, sa zdajú byť takmer identické s marxistickou teóriou. V&nbsp;preambule správy zo Svetovej konferencie o ľudských sídlach z roku 1976 sa píše: „Súkromné ​​vlastníctvo pôdy je hlavným nástrojom hromadenia bohatstva, a preto prispieva k sociálnej nespravodlivosti. Je preto nevyhnutná verejná&nbsp; kontrola využívania pôdy.“<a href="#_edn11" id="_ednref11">[11]</a> Po odchode do dôchodku žil Strong v&nbsp;Pekingu, ale zomrel v Kanade v roku 2015.</p>
        <p>Natalie Grant Wragaová, odborníčka na dezinformačné taktiky Sovietskeho zväzu, napísala v článku z roku 1998: „Ochrana životného prostredia sa stala hlavným nástrojom útoku proti Západu a všetkým hodnotám, ktoré zastáva. Ochrana životného prostredia môže byť použitá ako zámienka k prijatiu viacerých opatrení určených k podkopaniu priemyselnej základne rozvinutých krajín. Môže tiež slúžiť na vytvorenie nepokoja tým, že zníži životnú úroveň týchto krajín a vštepí im komunistické hodnoty.“<a href="#_edn12" id="_ednref12">[12]</a></p>
        <h2>c) Ekologický marxizmus</h2>
        <p>Na prelome 19. a 20. storočia britský vedec Arthur Tansley prišiel s pojmami ekológie a ekosystému. Tansley bol prvým predsedom Britskej ekologickej spoločnosti a počas štúdia na University College v Londýne ho hlboko ovplyvnil darwinistický zoológ Ray Lankester.<a href="#_edn13" id="_ednref13">[13]</a> Obidvaja boli fabiánski socialisti. Lankester bol ako Marxov priateľ častým hosťom v jeho dome. Raz Marxovi napísal, že s „najväčším potešením a prínosom“ študuje jeho <em>Kapitál </em>(Marxova kniha z roku 1867).<a href="#_edn14" id="_ednref14">[14]</a></p>
        <p>Práve v týchto vzťahoch Tansleyho, Lankestera a Marxa možno pozorovať rodiace sa väzby medzi ekologickým myslením a marxizmom. Zatiaľ čo environmentalizmus je ideológia zaoberajúca sa ochranou životného prostredia pred poškodením prírodného prostredia vo všeobecnosti, ekológia sa zaoberá vzťahom medzi živými vecami a ich prostredím. Tým poskytuje teoretickú základňu na to, aby definovala škodu, ktorá prírodnému prostrediu vznikla. Ekomarxismus rozvíja tieto myšlienky ešte ďalej a pridáva pojem „ekologická kríza“, aby rozšíril svoje argumenty o hospodárskom kolapse kapitalizmu. Snaží sa rozšíriť domnelý konflikt medzi buržoáziou a proletariátom tým, že do neho pridáva inherentný konflikt medzi výrobou a životným prostredím. Toto sa nazýva teória dvojitej krízy alebo dvojitého konfliktu. V marxistickej teórii je primárny konflikt kapitalizmu medzi výrobnými silami a vzťahmi vo výrobe, zatiaľ čo v sekundárnom konflikte dochádza ku konfliktu medzi výrobným prostredím (ekosystémom) a produktívnymi silami (kapitalizmom). Z pohľadu marxistov v tejto teórii vedie primárny konflikt k hospodárskej kríze, zatiaľ čo sekundárny konflikt vedie ku kríze ekologickej.<a href="#_edn15" id="_ednref15">[15]</a></p>
        <p>V minulom storočí sa predpoveď marxistov o zrútení kapitalizmu ukázala ako mylná. Kapitalizmus naopak naďalej prosperuje. Aby udržali v chode boj proti kapitalizmu a&nbsp;súkromnému podnikaniu, komunisti naplnili environmentalizmus &nbsp;marxistickou teóriou a osvojili si nový bojový pokrik o „ekologickom kolapse“.</p>
        <h2>d) Ekologický socializmus</h2>
        <p>Ako už názov napovedá, ekologický socializmus (ekosocializmus) je ideológia kombinujúca ekológiu a socializmus. Prepojením typicky socialistických požiadaviek, ako je „sociálna spravodlivosť“, s&nbsp;ekologickými záujmami, chce presadiť socialistické ideológie v novom prestrojení.</p>
        <p>Dobrým príkladom ekosocializmu je <em>Ekosocialistický manifest</em>, ktorý v roku 2001 napísali Joel Kovel a Michael Löwy. Kovel bol aktivistom proti vojne vo Vietname a neskôr sa stal profesorom psychiatrie. V roku 2000 sa tiež neúspešne uchádzal o&nbsp;prezidentskú nomináciu za Stranu zelených. Sociológ Löwy je členom trockistickej Štvrtej internacionály. V manifeste sa autori rozhodli „vybudovať hnutie, ktoré môže nahradiť kapitalizmus spoločnosťou, v&nbsp;ktorej bude kapitalistické vlastníctvo nahradené spoločným vlastníctvom výrobných prostriedkov a kde bude ochrana a obnova ekosystémov základnou súčasťou všetkej ľudskej činnosti“. Nevnímali ekosocializmus ako obyčajné odvetvie socializmu, ale skôr ako nové pomenovanie socializmu v novej ére.<a href="#_edn16" id="_ednref16">[16]</a></p>
        <h2>e) Zelená sa stáva novou červenou</h2>
        <p>Hneď ako environmentalizmus vstúpil do politiky, zrodila sa zelená politika či ekopolitika. Strany zelených, na ktoré teraz narazíme v mnohých krajinách, sú výsledkom zelenej politiky, ktorá zvyčajne nerieši iba ochranu životného prostredia, ale zahŕňa aj ľavicové programy ako sociálna spravodlivosť, feminizmus, protivojnový aktivizmus a pacifizmus. Napríklad Globálni zelení (Global Greens) je medzinárodná organizácia prepojená so Stranou zelených a jej charta z roku 2001 je silne ovplyvnená marxistickou ideológiou.<a href="#_edn17" id="_ednref17">[17]</a> Po páde komunistických režimov vo východnej Európe mnoho bývalých členov komunistickej strany a zostávajúcich komunistických skupín založilo strany zelených alebo sa pripojili k&nbsp;tým existujúcim, čím posilnili ľavicový charakter zelenej politiky.</p>
        <p>Bývalý sovietsky vodca Gorbačov sa tiež pokúsil znovu vstúpiť do politiky, avšak neúspešne. Potom prešiel k environmentalizmu a založil Medzinárodný zelený kríž (Green Cross International). Gorbačov často podporoval zriadenie svetovej vlády, aby zabránil ekologickej katastrofe.<a href="#_edn18" id="_ednref18">[18]</a></p>
        <p>Mnoho komunistických strán na Západe je priamo zapojených do hnutí na ochranu životného prostredia. Jack Mundey, odborový aktivista a spoluzakladateľ austrálskeho hnutia Green Ban, bol členom austrálskej komunistickej strany a jeho manželka bola v rokoch 1979 až 1982 v tejto strane národnou predsedníčkou.<a href="#_edn19" id="_ednref19">[19]</a></p>
        <h2>f) Zmanipulovaná rétorika environmentalizmu</h2>
        <p>Použitie masových hnutí je jednou zo stratégií, ktoré komunizmus využíva na šírenie svojho vplyvu medzi národmi po celom svete. Mnoho ekologických organizácií mobilizuje veľké množstvo ľudí a vedie kampane na boj za ochranu životného prostredia. Lobujú a&nbsp;napádajú vládne inštitúcie, aby formulovali a presadzovali nerozumné dohody a opatrenia. Vyvolali tiež násilné incidenty, aby umlčali širokú verejnosť.</p>
        <p>Ako uviedol radikálny ľavičiar Saul Alinsky, skutočné ciele hnutia je nutné skryť, a namiesto toho je potrebné mobilizovať ľudí vo veľkom rozsahu, aby podporovali miestne, dočasné, vierohodné alebo pozitívne ciele. Keď si ľudia zvyknú na tieto relatívne umiernené formy aktivizmu, je pomerne ľahké nechať ich, aby konali v mene radikálnejších cieľov. „Pamätajte: hneď ako zorganizujete ľudí okolo tém, na ktorých sa všetci zhodnú, ako napríklad znečistenie, potom je organizovaný ľud v pohybe. Odtiaľ už je len krátky a prirodzený krok k politickému znečisteniu, znečisteniu Pentagonu,“ napísal Alinsky.<a href="#_edn20" id="_ednref20">[20]</a></p>
        <p>Rad ľavicových skupín používa environmentalizmus ako ideologický obal pre pouličné akcie, ktoré obhajujú revolúciu. Pokiaľ má napríklad krajina „hnutie ľudí za klímu“, môžeme z&nbsp;toho usudzovať, že ide o produkt komunistickej strany. V&nbsp;Spojených štátoch sú zúčastnenými organizáciami Komunistická strana USA, Socialisti v akcii, Maoistická revolučná komunistická strana USA, Americká ekologická spoločnosť, Socialistická strana robotníkov, Socialistická alternatíva, Demokratickí socialisti Ameriky atď. Takéto skupiny usporadúvali napríklad Pochod ľudí za klímu a pochodovali so záplavou červených vlajok veľkými americkými mestami, vrátane Washingtonu DC. Na týchto pochodoch sa objavili napríklad slogany „Zmena systému, nie zmena klímy“, „Kapitalizmus nás zabíja“, „Kapitalizmus ničí životné prostredie“, „Kapitalizmus zabíja planétu“ alebo „Boj o socialistickú budúcnosť“.<a href="#_edn21" id="_ednref21">[21]</a></p>
        <p>S rastúcim počtom komunistických a socialistických prvkov, ktoré posilnili environmentalizmus, sa „zelený mier“ („green peace“) naplno premenil na červenú revolúciu.</p>
        <h2>g) Ekoterorizmus</h2>
        <p>Vzhľadom k ľavicovým vplyvom je environmentalizmus pomerne radikálny už od svojho vzniku. Environmentalizmus má mnoho odvetví, ako je napríklad hlbinná ekológia, ekofeminizmus, sociálna ekológia a bioregionalizmus, z ktorých niektoré sú extrémne radikálne. Medzi najznámejšie skupiny patria Earth First! (Zem na prvom mieste!) a Earth Liberation Front (Front za oslobodenie Zeme). Aby ich stúpenci zastavili činnosti, ktoré považujú za škodlivé životnému prostrediu, využívajú priamu akciu. Často však ide o deštruktívne aktivity známe ako ekoterorizmus.</p>
        <p>Earth First! získala svoj názov v roku 1979 a jej sloganom je&nbsp; „Žiadne kompromisy pri ochrane matky Zeme!“. Skupina sa zameriava na činnosti ako je ťažba dreva, stavanie priehrad a ďalšie projekty. K protestom používa priame akcie a „kreatívnu občiansku neposlušnosť“. Jedna zo známych taktík skupiny sa nazýva „sedenie na stromoch“ (tree sitting), kedy aktivisti sedia pod stromom alebo sa na neho vyšplhajú, aby zabránili jeho vyrúbaniu. Tieto akcie prilákali mnoho ľavičiarov, anarchistov a ďalších, ktorí sa snažia vzbúriť proti hlavnému prúdu spoločnosti.</p>
        <p>V roku 1992 niektorí z radikálnejších členov založili vetvu nazvanú Earth Liberation Front (Front za oslobodenie Zeme, ELF). Odkopírovali skratku názvu skupiny Environmental Life Force (Sila životného prostredia), ktorá bola rozpustená v roku 1978, a&nbsp;prijali jej partizánske taktiky, najmä podpaľačstvo. V decembri 2000 sa ELF dopustila radu zločinov na Long Islande v New Yorku. Radikáli rozbili stovky okien a nastriekali graffiti na fasády bytovej výstavby a kancelárie firmy McDonald’s. Zapálili tiež šestnásť budov v bytovej výstavbe a najmenej štyri luxusné domy. Hlavnou obhajobou ich podpaľačstva bolo to, že domy boli „budúcimi brlohmi bohatých elít“ a stavali sa na území lesov a mokradí. Na týchto priamych akciách použila ELF slogan „Ak ich postavíte, my ich zapálime!“.<a href="#_edn22" id="_ednref22">[22]</a></p>
        <p>V roku 2005 FBI oznámila, že ELF spoločne s ďalšími extrémistickými skupinami sú vážnou teroristickou hrozbou pre Spojené štáty. Od roku 1990 sa podľa FBI tieto skupiny podieľali na viac ako 1 200 kriminálnych incidentoch, pri ktorých došlo k&nbsp;škodám na majetku v hodnote desiatok miliónov dolárov.<a href="#_edn23" id="_ednref23">[23]</a> Činy organizácie už dávno prekračujú hranice bežného politického protestu alebo rozdielnosti v názoroch. Komunistická ideológia využila nenávisť, aby premenila niektorých environmentalistov na teroristov.</p>
        <h2>h) Greenpeace: nie práve mierumilovný príbeh</h2>
        <p>Organizácia Greenpeace (v preklade „zelený mier“) bola založená v roku 1971 a je&nbsp; najväčšou ekologickou organizáciou na svete, s pobočkami vo viac ako päťdesiatich krajinách a s&nbsp;ročným príjmom presahujúcim 350 miliónov dolárov. Je tiež jednou z&nbsp;radikálnejších ekologických organizácií.</p>
        <p>Spoluzakladateľ Greenpeace Paul Watson, ktorý organizáciu v roku 1977 opustil, povedal: „Tajomstvo úspechu [bývalého predsedu] Davida Mctaggarta je tajomstvom úspechu Greenpeace: nezáleží na tom čo je pravda, záleží len na tom, čo ľudia za pravdu považujú. ... Ste tým, za koho vás označia médiá. [Greenpeace] sa stala mýtom a strojom na vytváranie mýtov.“<a href="#_edn24" id="_ednref24">[24]</a></p>
        <p>Patrick Moore, ďalší spoluzakladateľ Greenpeace, sa venoval ochrane životného prostredia, avšak po 15 rokoch organizáciu opustil s odôvodnením, že „sa ostro obrátila k politickej ľavici“. Z Greenpeace sa podľa neho stala extrémistická organizácia prejavujúca nepriateľstvo voči akejkoľvek priemyselnej výrobe a odráža agendu založenú viac na politike než na skutočnej vede.<a href="#_edn25" id="_ednref25">[25]</a></p>
        <p>V roku 2007 sa šesť členov Greenpeace vlámalo do britskej uhoľnej elektrárne. Následne boli &nbsp;zažalovaní za spôsobenie škody vo výške okolo 30 tisíc britských libier. Svoj pokus o&nbsp;odstavenie elektrárne priznali , tvrdili však, že to urobili, aby zabránili ešte väčším škodám (teda environmentálnej kríze spôsobenej skleníkovými plynmi). Súd všetkých šesť aktivistov zbavil obvinenia. Aj pred tým si však Greenpeace pripísal na účet niekoľko súdnych víťazstiev pri činoch, akými bolo poškodenie stíhacieho lietadla, jadrového podmorského vybavenia a obsadenie najväčšej britskej spaľovne odpadu.<a href="#_edn26" id="_ednref26">[26]</a></p>
        <p>Stratégia, ktorú radikálne environmentálne organizácie ako Greenpeace používajú, je využiť na dosiahnutie svojich cieľov akékoľvek nevyhnutné prostriedky. V tomto bode je radikálny environmentalizmus s komunizmom vysoko konzistentný. Tradičný marxizmus-leninizmus na ospravedlnenie zabíjania, podpaľačstva a lúpeží používa prísľub utópie. Podobným spôsobom komunisti pod vlajkou environmentalizmu zdôrazňujú ekologické krízy na legitimizáciu svojich násilných a nezákonných taktík.</p>
        <p>Vo vyššie uvedenom príklade právnici šiestich členov Greenpeace presvedčili porotu, aby prijala ich trestnú činnosť ako legitímnu, čo dokazuje, že spoločnosť môže byť pochybnými a neodôvodnenými argumentmi uvedená do omylu. To všetko je súčasťou opustenia univerzálnych hodnôt a svedčí to o morálnom úpadku spoločnosti.</p>
        <h1>2. Zmena klímy</h1>
        <p>Zmena klímy sa v dnešnej spoločnosti stala predmetom horúcej diskusie, do ktorej sa zapojilo veľa celebrít, mediálnych osobností, politikov i zástupcov širokej verejnosti. Najčastejšie možno pri týchto diskusiách počuť tvrdenie, že ľudia spôsobili nadmernou emisiou skleníkových plynov globálne otepľovanie, čo vraj povedie ku klimatickým katastrofám.</p>
        <p>Zástancovia tejto teórie tvrdia, že tento záver bol dosiahnutý vedeckým konsenzom a ide o podložený vedecký názor. Podľa niektorých environmentalistov ľudia, ktorí tento záver odmietajú, sú proti vede a dokonca proti ľudstvu. Hlasy tých, ktorí sa stavajú proti prevládajúcemu názoru, sú potlačené a v médiách alebo akademických časopisoch sa objavujú len zriedka, aby sa tak udržal obraz konsenzu.</p>
        <p>Vyššie uvedení členovia Greenpeace, ktorí poškodili elektráreň, boli nakoniec zbavení viny, čiastočne preto, pretože v ich prospech svedčil slávny odborník, zástanca tohto údajného konsenzu. Tvrdil napríklad, že množstvo oxidu uhličitého denne vypusteného elektrárňou povedie k zániku až štyristo živočíšnych druhov.</p>
        <p>Mnoho členov vedeckej komunity, medzi nimi Richard Lindzen, profesor meteorológie na dôchodku, predtým pôsobiaci na Massachusettskom technologickom inštitúte, a Steven Koonin, bývalý zástupca tajomníka pre vedu na americkom ministerstve pre energetiku, napísalo, že veda o klíme nie je v skutočnosti uzavretou záležitosťou a&nbsp;že nám chýbajú dostatočné znalosti, ktoré sú potrebné na vytvorenie rozumnej klimatickej politiky.<a href="#_edn27" id="_ednref27">[27]</a> <a href="#_edn28" id="_ednref28">[28]</a></p>
        <h2>a) Potláčanie opozičných hlasov</h2>
        <p>V jednom článku Koonin napísal:</p>
        <p>Verejnosť do značnej miery nevie o intenzívnych diskusiách, ktoré prebiehajú v rámci klimatológie. Na nedávnom stretnutí národných laboratórií som bol svedkom, ako viac ako sto aktívnych vládnych a&nbsp;univerzitných vedcov spolu často rozhorčene debatovalo v snahe oddeliť ľudský vplyv od prirodzenej premenlivosti klímy. A&nbsp;nešlo iba o nuansy, ale o základné aspekty nášho porozumenia, pokiaľ ide o zjavné – avšak neočakávané – spomalenie globálneho zvyšovania hladiny mora v posledných dvoch desaťročiach.<a href="#_edn29" id="_ednref29">[29]</a></p>
        <p>Medzi otázky, ktorými sa vedci v horúcej debate zaoberajú, patrí okrem iného aj to, či je prírodné otepľovanie primárne spôsobené ľudskou činnosťou, alebo prírodnými faktormi; ako veľmi sa svet oteplí do konca 21. storočia; či má ľudstvo vôbec schopnosť predpovedať, ako sa v budúcnosti zmení&nbsp; klíma, a či existuje hroziaca ekologická katastrofa.</p>
        <p>Fyzik Michael Griffin, bývalý pracovník NASA, v&nbsp;rozhovore pre National Public Radio v roku 2007 uviedol:</p>
        <p>Nepochybujem o tom, že ... trend globálneho otepľovania existuje. Nie som si však istý, či je primerané povedať, že je to problém, s&nbsp;ktorým musíme zápasiť. Predpokladať, že je to problém, je ako predpokladať, že dnešný stav klímy Zeme je optimálny a najlepší, aký sme kedy mali, a že musíme podniknúť kroky k tomu, aby sme zaistili, že sa nezmení. Po prvé si nemyslím, že je v moci ľudí zabezpečiť, aby sa klíma nezmenila, ako ukázali milióny rokov histórie, a po druhé by som sa spýtal, ktorým ľudským bytostiam – kde a kedy – má byť udelené privilégium rozhodnúť, že toto konkrétne podnebie, ktoré tu dnes máme, je práve teraz tým najlepším prostredím pre všetky ďalšie ľudské bytosti. Myslím, že by bolo dosť arogantné, keby ľudia mali túto pozíciu zaujať.<a href="#_edn30" id="_ednref30">[30]</a></p>
        <p>&nbsp;</p>
        <p>Hoci Griffin prejavoval pokoru, ktorú by vedeckí pracovníci mali mať, okamžite sa stretol s drvivou kritikou zo strany médií a niektorých klimatológov, ktorí jeho poznámky vyhlásili za ignorantské. Nasledujúci týždeň sa Griffin na uzavretom stretnutí v Jet Propulsion Laboratory v Pasadene v Kalifornii za vyvolanie kontroverzie zamestnancom NASA ospravedlnil.<a href="#_edn31" id="_ednref31">[31]</a></p>
        <p>O niekoľko mesiacov neskôr v rozhovore pre publikáciu NASA Griffin uviedol: „Osobne si myslím, že to ľudia pri diskusii o&nbsp;zmene klímy preháňajú, až tak, že je takmer nelegitímne považovať ju za technický predmet. Získala takmer náboženský status, čo považujem za poľutovaniahodné.“</p>
        <p>Z Griffinovho postrehu môžeme pozorovať, že použitie všetkých prostriedkov na potlačenie vedeckej debaty samo osebe narušuje ducha vedy, pretože vedecký pokrok je sám osebe výsledkom debaty: „Rozvíjate svoje teórie, publikujete svoje dáta, podporujete svoju koncepciu, a ostatní ju chcú vyvrátiť alebo sa o to pokúšajú. Vedecký konsenzus sa vyvíja týmto spôsobom,“ uviedol.<a href="#_edn32" id="_ednref32">[32]</a></p>
        <p>Podobnú skúsenosť ako Griffin zažil švédsky meteorológ Lennart Bengtsson, ktorý sa stretol s okamžitým a&nbsp;intenzívnym odporom zo strany svojich kolegov po celom svete, keď bol požiadaný, aby sa pripojil k predsedníctvu think-tanku Global Warming Policy Foundation (GWPF, Nadácia politiky globálneho otepľovania), ktorý spochybňuje teórie o globálnom otepľovaní. Nátlak bol taký intenzívny, že cítil, že z nadácie musí odstúpiť, čo tiež do dvoch týždňov urobil.</p>
        <p>Vo svojom rezignačnom liste Bengtsson Napísal:</p>
        <p>Bol som v posledných dňoch vystavený takému veľkému kolektívnemu tlaku z celého sveta, že sa to pre mňa stalo v podstate neznesiteľným. Ak by to pokračovalo, nemohol by som vykonávať svoju normálnu prácu, a dokonca sa obávam, že by mohlo byť ohrozené moje zdravie a bezpečnosť. Nevidím preto žiadne iné východisko, než rezignáciu z GWPF. Taký obrovský celosvetový nátlak od komunity, ku ktorej som mal po celý svoj aktívny život tak blízko, som nečakal. Niektorí kolegovia zrušili svoju podporu, ďalší odstúpili od spoluautorstva atď.</p>
        <p>Nevidím žiadne hranice a koniec toho, čo sa stane. Je to situácia, ktorá mi pripomína dobu McCarthyho. Nikdy by som neočakával nič podobného v takej pôvodne mierumilovnej komunite, ako je meteorológia. Očividne sa v posledných rokoch zmenila.<a href="#_edn33" id="_ednref33">[33]</a></p>
        <p>&nbsp;</p>
        <p>Táto transformácia, ktorú Bengtsson pozoroval, je výsledkom komunistickej ideológie a bojovej taktiky, ktoré vstúpili do environmentálnej vedy.</p>
        <p>Údajný vedecký konsenzus ohľadom zmeny klímy premenil teóriu o zmene klímy na dogmu. Zmena klímy ktorá je kľúčovým princípom dnešného environmentalizmu, je považovaná za posvätnú a nedotknuteľnú. Vedci, médiá a environmentálni aktivisti, ktorí tento princíp prijali, pracujú spoločne na šírení viery hlásajúcej bezprostrednú ekologickú katastrofu. Ide o dôležitý nástroj ekologického hnutia k vydeseniu verejnosti, aby prijala ľavicovú politickú agendu, vrátane zvýšenia daní a prevzatia kontroly veľkou vládou. To všetko za účelom „zachrániť“ spoločnosť pred skazou. Pri procese vytvárania a upevňovania tejto dogmy sú zjavne viditeľné techniky komunistického politického boja – klamanie, mobbing (znepríjemňovanie života, šikanovanie jednotlivca), verejné zneucťovanie a otvorený konflikt.</p>
        <h2>b) „Konsenzus“ v&nbsp;klimatológii</h2>
        <p>V roku 1988 bol pod záštitou OSN zriadený medzivládny panel o zmene klímy IPCC, aby posúdil a zjednotil vedu súvisiacu so zmenou klímy. Jedným z jeho poslaní je vyhodnocovanie existujúceho vedeckého výskumu o zmene klímy a každých niekoľko rokov vydáva hodnotiacu správu. Účelom týchto správ je poskytnúť vládam vedecký základ pri tvorbe politiky. Píšu ju stovky vedcov a recenzujú ju tisíce ďalších. Závery v správach sú preto často popisované ako konsenzus tisícov najlepších vedcov na svete.</p>
        <p>V roku 1992 uvádzal Rámcový dohovor OSN o zmene klímy (UNFCCC), že jeho cieľom je stabilizovať koncentráciu skleníkových plynov v atmosfére na úroveň, ktorá by zabránila nebezpečnému, človekom spôsobenému zasahovaniu do klimatického systému. Bol postavený na tvrdení, že zmena klímy je spôsobená ľuďmi a je nebezpečná.<a href="#_edn34" id="_ednref34">[34]</a> UNFCCC preto vychádza z premisy, že ľudia sú vinníkmi nebezpečnej zmeny klímy, pretože je to nevyhnutné pre existenciu a prežitie tejto organizácie. Tento predpoklad tiež obmedzil zameranie a rozsah výskumu IPCC.<a href="#_edn35" id="_ednref35">[35]</a></p>
        <h3>Zo správ IPCC boli odstránené vyhlásenia vyjadrujúce neistotu</h3>
        <p>Predtým, než medzivládny panel IPCC vydal v roku 1995 druhú hodnotiacu správu, svetovo uznávaný fyzik Frederick Seitz získal jej kópiu. Seitz neskôr zistil, že záverečná správa nebola&nbsp; rovnakou verziou, ako tá, čo schválili zúčastnení vedci. Všetky vyhlásenia vyjadrujúce neistotu ohľadom účinkov ľudskej činnosti na zmenu klímy boli vymazané.</p>
        <p>Seitzov článok v <em>The Wall Street Journal </em>uvádza: „Za viac ako 60 rokov, kedy som bol členom americkej vedeckej komunity ako bývalý predseda Národnej akadémie vied a Americkej fyzikálneho spoločnosti, som nikdy nebol svedkom znepokojujúcejšej korupcie v procese posudzovania než pri udalostiach spojených s touto správou IPCC.“<a href="#_edn36" id="_ednref36">[36]</a></p>
        <p>Medzi vymazanými vyhláseniami boli napríklad tieto:</p>
        <p>„Žiadna z uvedených štúdií nepriniesla jasný dôkaz, že môžeme pozorované zmeny [klímy] pripisovať konkrétnej príčine nárastu skleníkových plynov.“„Žiadna doterajšia štúdia úplne a ani sčasti neprisúdila [doteraz pozorované klimatické zmeny] antropogénnym príčinám.“„Akékoľvek tvrdenia o pozitívnej detekcii významnej zmeny klímy pravdepodobne zostanú kontroverzné, kým nebudú zredukované neistoty ohľadom celkovej prirodzenej variability klimatického systému.“<a href="#_edn37" id="_ednref37">[37]</a></p>
        <p>IPCC tvrdil, že autori všetky zmeny schválili, avšak zmeny odhaľujú, ako bola správa IPCC ovplyvnená politikou. Správa väčšinou zhŕňa existujúce štúdie bez pridania pôvodného výskumu. Pretože existujúci výskum obsahoval veľa odlišných názorov, IPCC dosiahol konsenzus tým, že sa jednoducho zbavil názorov, ktoré stáli v ceste.</p>
        <p>V apríli 2000 prvý návrh tretej hodnotiacej správy IPCC uvádzal: „Existuje zrejmý vplyv človeka na globálnu klímu.“ Druhý návrh z októbra tvrdil: „Je pravdepodobné, že zvýšenie koncentrácie antropogénnych skleníkových plynov výrazne prispelo k pozorovanému otepľovaniu za posledných 50 rokov.“ V záverečnej oficiálnej správe bolo vyhlásenie ešte dôraznejšie: „Väčšina pozorovaného otepľovania za posledných 50 rokov je pravdepodobne spôsobená zvýšenou koncentráciou skleníkových plynov.“</p>
        <p>Keď bol hovorca Programu OSN pre životné prostredie Tim Higham požiadaný, aby oznámil, na akom vedeckom základe došlo k zmenám vo formuláciách správy, povedal pre vedecký časopis <em>New Scientist</em>: „Nemali sme k dispozícii žiadne nové vedecké zistenia, ale vedci chceli politikom predniesť jasné a silné posolstvo.“<a href="#_edn38" id="_ednref38">[38]</a></p>
        <p>Inými slovami, UNFCCC zadal IPCC úlohu s vopred daným výsledkom. IPCC potom len dodal to, čo sa od neho vyžadovalo.</p>
        <h3>V správe IPCC bol zveličený „konsenzus o katastrofe“</h3>
        <p>Paul Reiter, profesor lekárskej entomológie na Pasteurovom inštitúte vo Francúzsku, je popredným odborníkom na maláriu a&nbsp;ďalšie choroby prenášané hmyzom. Nesúhlasil so správou IPCC a vyhrážal sa začatím súdneho konania proti IPCC, ak ten neodstráni jeho meno zo zoznamu najlepších dvetisíc vedcov, o ktorých sa tvrdilo, že správu schválili. Spomenul, že IPCC „Chce navodiť dojem, že všetci poprední vedci sú v zhode, čo však nie je pravda“.<a href="#_edn39" id="_ednref39">[39]</a></p>
        <p>Vo svojej výpovedi počas vypočutia v Senáte USA z 26. apríla 2006 Reiter uviedol: „Znepokojivým aspektom debaty je, že túto falošnú, ‚vedu‘, potvrdzujú na verejnom fóre vplyvné skupiny, ‚odborníkov‘. Mám na mysli najmä Medzivládny panel pre klimatické zmeny (IPCC). Každých päť rokov táto organizácia, ktorá patrí pod OSN, vydáva ,konsenzus popredných svetových vedcov‘ vo všetkých aspektoch zmeny klímy. Nehľadiac na pochybný proces, ktorým sú títo vedci vyberaní, je taký konsenzus dôsledkom politiky, a nie vedy.“<a href="#_edn40" id="_ednref40">[40]</a></p>
        <p>Environmentalisti propagujú teóriu, že choroby prenášané hmyzom, ako je malária, sa pri otepľovaní klímy vymknú spod kontroly. „Podľa správy OSN vystaví globálne otepľovanie ďalšie milióny ľudí riziku malárie a&nbsp;horúčky dengue. Správa&nbsp; požaduje naliehavé preskúmanie zdravotných rizík, ktoré zmena klímy predstavuje“, uviedol Bloomberg vo svojom článku z 27. novembra 2007.<a href="#_edn41" id="_ednref41">[41]</a></p>
        <p>Reiter však s takouto jednoduchou koreláciou nesúhlasí. Poukázal na to, že výskyt malárie sa neobmedzuje na tropické oblasti. Počas obdobia Ruskej ríše a Sovietskeho zväzu na konci 19. a na začiatku 20. storočia zomieralo na túto chorobu päť miliónov ľudí ročne.<a href="#_edn42" id="_ednref42">[42]</a> Štúdia z&nbsp;roku 2011 publikovaná v <em>Biology Letters</em> zistila, že napriek predchádzajúcemu predpokladu rastúce teploty znižujú infekčnosť komárov a spomaľujú prenos malárie.<a href="#_edn43" id="_ednref43">[43]</a></p>
        <p>Ďalší vedec, ktorý vystúpil z IPCC, obvinil organizáciu z použitia údajného „konsenzu o katastrofe“ ako súčasti jej kultúry fungovania. Meteorológ Christopher Landsea, bývalý výskumník hurikánov na americkom Národnom úrade pre oceány a atmosféru a popredný autor štvrtej hodnotiacej správy IPCC, vystúpil z IPCC v januári 2005. V otvorenom liste uviedol: „Osobne v dobrej viere nemôžem naďalej podporovať proces, o ktorom sa domnievam, že je motivovaný vopred pripravenou agendou a je z&nbsp;vedeckého hľadiska nespoľahlivý.“ Naliehavo žiadal&nbsp; IPCC, aby potvrdil, že sa správa bude riadiť vedou a nebude mať sklony k&nbsp;naháňaniu senzácií.<a href="#_edn44" id="_ednref44">[44]</a></p>
        <p>Landsea kritizoval hlavného autora kapitoly správy IPCC o&nbsp;hurikánovej aktivite kvôli ignorovaniu vedeckých štúdií, ktoré nemohli preukázať, že zvýšená hurikánová aktivita súvisí s&nbsp;globálnym otepľovaním spôsobeným človekom. Namiesto toho popredný autor správy vystúpil na vysoko sledovanej tlačovej konferencii, kde tvrdil, že globálne otepľovanie „pravdepodobne bude aj naďalej vyvolávať ďalšie ohniská intenzívnej hurikánovej aktivity“ a pred zverejnením správy poskytol niekoľko rozhovorov, ktoré prezentovali ten istý pohľad.</p>
        <p>David Deming, geológ a geofyzik na University of Oklahoma, získal vďaka štúdiu vzoriek ľadu dáta o teplote v Severnej Amerike za uplynulých 150 rokov. O svojom výskume napísal článok, ktorý vyšiel v časopise <em>Science</em>. Podľa Demingovho vlastného svedectva pri vypočutí v Senáte USA v roku 2006 mu po zverejnení článku „významný výskumný pracovník v oblasti zmeny klímy“ zaslal e-mail, v ktorom mu napísal: „Musíme sa zbaviť stredovekého klimatického optia.“ Stredovekým klimatickým optiom sa označuje neobvykle teplé počasie, ktoré sa začalo okolo roku 1000 a trvalo až do chladného obdobia v 14. storočí, známeho ako „malá doba ľadová“. Ku štúdiám, ktoré hovoria, že stredoveké klimatické optium existovalo, prispelo podľa Deminga v priebehu 20 rokov viac ako 780 vedcov zo 462 inštitúcií v 40 krajinách. Vymazanie tohto obdobia z&nbsp;historickej krivky zmien klímy by však posilnilo tvrdenie, že akékoľvek dnešné otepľovanie je bezprecedentné.<a href="#_edn45" id="_ednref45">[45]</a></p>
        <p>Hoci údajný konsenzus IPCC vyvracajú stovky vedeckých prác, tvrdenia týchto vedeckých správ sú v súčasnom akademickom a&nbsp;mediálnom prostredí marginalizované.</p>
        <h2>c) Zriadenie dogmy vo vedeckej komunite</h2>
        <p>Publicita okolo zmeny klímy vyvolaná komunistickými silami má za cieľ nielen pripraviť cestu pre globálnu vládu, ale aj zničiť etiku výskumu vo vedeckej komunite.</p>
        <p>Klimatológia je pomerne mladý odbor, vznikol len pred niekoľkými desaťročiami. Jeho hypotézy ohľadom globálneho otepľovania sú však už brané ako fakty. Médiá tému globálneho otepľovania propagujú palcovými titulkami, a pritom zakrývajú nepresnosti, ktoré sa vo vedeckom výskume objavujú. Svetové vlády vlievajú nemalé finančné prostriedky do výskumu hypotézy o globálnom otepľovaní, zatiaľ čo prehliadajú ostatné zistenia. Médiá a politici označujú predpoveď o katastrofickej zmene klímy ako „vedecky preukázanú“, a šíria ju po celom svete ako nenapadnuteľnú doktrínu. Mienka širokej verejnosti je tým veľmi jednostranne ovplyvnená a ľudia nie sú schopní ľahko rozlíšiť správne od nesprávneho.</p>
        <p>Ak sa to dovedie do konca, prirodzeným cieľom tejto trajektórie bude vytvorenie globálnej supervlády – t. j. komunizmu – za údajným cieľom záchrany Zeme a ľudstva pred vymyslenou alebo veľmi zveličenou krízou. Zničiť starý svet akýmkoľvek spôsobom je základnou stratégiou komunizmu.</p>
        <p>Bez ohľadu na to, akú má vedec akademickú povesť, hneď ako verejne vyjadrí pochybnosti o dogme konsenzu, okamžite začne čeliť ohromnému tlaku od kolegov a akademických inštitúcií. Niektorí ľudia dokonca tvrdili, že tí, ktorí sú ohľadom globálneho otepľovania skeptickí, by mali byť stíhaní alebo kriminalizovaní. Ľudia, ktorí žili v komunistickej totalitnej spoločnosti zažili podobnú skúsenosť, keď akokoľvek spochybňovali dogmu komunistickej strany.</p>
        <p>Zosnulý populárny britský botanik a predseda spoločnosti The Wildlife Trusts, David Bellamy, ktorý počas svojej kariéry napísal desiatky kníh a spisov, verejne vyhlásil, že neverí v dogmu konsenzu ohľadom globálneho otepľovania. The Wildlife Trusts zareagovala vyhlásením, v ktorom vyjadrila svoju nespokojnosť, a&nbsp;hneď ako Bellamymu o niekoľko mesiacov neskôr skončilo funkčné obdobie, organizácia ho vylúčila.<a href="#_edn46" id="_ednref46">[46]</a> Environmentalisti, ktorí mu predtým preukazovali úctu, začali spochybňovať jeho mentálne zdravie.<a href="#_edn47" id="_ednref47">[47]</a></p>
        <p>Zosnulý uznávaný profesor William Gray bol&nbsp; priekopníkom amerického výskumu hurikánov. Potom čo kritizoval konsenzuálnu dogmu ohľadom človekom spôsobeného globálneho otepľovania, jeho návrhy výskumných projektov boli opakovane zamietané.<a href="#_edn48" id="_ednref48">[48]</a></p>
        <p>Patrick J. Michaels, bývalý predseda Americkej asociácie štátnych klimatológov, pracuje ako klimatológ na Univerzite vo Virgínii a je spoluautorom knihy <em>Klíma extrémov:</em> <em>Čo nemáte vedieť o globálnom otepľovaní</em> (Climate of Extremes: Global Warming Science They Do not Want You to Know). V knihe uvádza početné príklady toho, ako environmentalisti potláčali vedecké názory za účelom dosiahnutia údajného konsenzu. Keďže Michaels zastával názor, že zmeny klímy nebudú mať nevyhnutne za následok katastrofu – a tento postoj bol v rozpore s dogmou konsenzu – guvernér štátu Virginia mu nariadil, aby sa ku globálnemu otepľovaniu prestal ako štátny klimatológ vyjadrovať. Michaels sa nakoniec rozhodol rezignovať.<a href="#_edn49" id="_ednref49">[49]</a></p>
        <p>Pridružený štátny klimatológ vo Washingtone Mark Albright bol zbavený funkcie po incidente v súvislosti so zavádzajúcim vyhlásením starostu Seattlu. Starosta tvrdil, že „priemerná snehová pokrývka v [Kaskádových vrchoch] klesla od roku 1950 o 50 %“. Albright začal zasielať svojim kolegom dáta, ktoré ukazovali, že snehová pokrývka v Kaskádových vrchoch od 70. rokov naopak rástla. Vtedajší štátny klimatológ ho požiadal, aby svoje e-maily odosielal až po predchádzajúcom schválení, a keď Albright odmietol, bol zbavený funkcie.<a href="#_edn50" id="_ednref50">[50]</a></p>
        <p>V komunistických krajinách je hrubé politické zasahovanie do vedy bežné. V&nbsp;západných krajinách sa na zasahovanie do akademickej slobody používa politika environmentalizmu. Akademický výskum, ktorý spochybňuje dogmu konsenzu, možno v&nbsp;akademických časopisoch vidieť len zriedka, a takto je to už od 90. rokov. Vo filmovom dokumente z roku 1990, <em>Konšpirácia o&nbsp;skleníkovom efekte</em> (The Greenhouse Conspiracy), Michaels uviedol, že sa raz spýtal editora, prečo jednu z jeho štúdii odmietli vydať. Bolo mu povedané, že jeho práca podlieha vyššiemu hodnotiacemu štandardu než ostatné.<a href="#_edn51" id="_ednref51">[51]</a> Podľa správy IPCC z roku 1990 sa v tej dobe uznávalo, že miera globálneho otepľovania zodpovedá prírodným zmenám klímy. Hoci sa teda Michaelsov pohľad líšil od názoru mnohých iných, nemohol byť v tom čase ešte považovaný za kacírsky. Cieľ dosiahnutia falošného konsenzu bol však už stanovený a všetci sa ním museli riadiť.</p>
        <p>V marci 2008 usporiadali vedci, ktorí spochybňovali dogmu konsenzu, súkromnú akademickú akciu v New Yorku, kde diskutovali o svojich pochybnostiach ohľadom klimatickej dogmy. Mnohí z týchto vedcov uviedli, že pri pokusoch zverejniť svoj výskum v akademických časopisoch, narazili na rôzne prekážky. Meteorológ Joseph D’Aleo, bývalý predseda Výboru pre analýzu a predpoveď počasia Americkej meteorologickej spoločnosti, uviedol, že niektorí jeho kolegovia sa neodvážili stretnutia zúčastniť, pretože mali strach, že by to mohlo ohroziť ich zamestnanie. Vyjadril názor, že „veľmi pravdepodobne existuje tichá väčšina“ vedcov z oblasti klimatológie, meteorológie a príbuzných vied, ktorí s názorom obhajovaným v tzv. „konsenze“ nesúhlasia.<a href="#_edn52" id="_ednref52">[52]</a></p>
        <p>Klimatologička Judith Curryová, bývalá vedúca Fakulty krajinských a atmosférických vied na Georgijskom technickom inštitúte, svedčila na vypočutí Senátu v roku 2015, že raz dostala e-mail od vedca zamestnaného v NASA, ktorý jej oznámil: „Bol som na menšom stretnutí vedcov pridružených k NASA. Vrcholový manažér mi povedal, že mu jeho šéf NASA prikázal, aby sme sa nepokúšali publikovať príspevky v rozpore so súčasnými tvrdeniami o&nbsp;globálnom otepľovaní, pretože potom by (šéf NASA) mal problémy kvôli ‚nežiadúcej‘ publicite.“</p>
        <p>Curryová ďalej v Senáte uviedla:</p>
        <p>Klimatológ, ktorý pri diskusii o klíme vyjadrí určitú mieru pochybností alebo neistoty, je zaškatuľkovaný ako popierač či „obchodník s pochybnosťami“, ktorého motívy sú považované za ideologické alebo poháňané financovaním zo strany priemyslu fosílnych palív. Moja vlastná skúsenosť potom, čo som zverejnila svoje obavy o tom, ako neistotu charakterizuje IPCC, je taká, že som bola označená za „klimatického kacíra“, ktorý sa obrátil proti svojim kolegom. Vznikol obrovský tlak na to, aby sa klimatológovia prispôsobili takzvanému konsenzu. Tento tlak pochádza nielen od politikov, ale aj od vládou financovaných organizácií, univerzít a odborných spoločností, či samotných vedcov, ktorí sa stali zelenými aktivistami a obhajcami. Tento konsenzus je umocňovaný silnými finančnými, osobnými a mocenskými záujmami.<a href="#_edn53" id="_ednref53">[53]</a></p>
        <p>&nbsp;</p>
        <p>Curryová sa v januári 2017 aj napriek svojej doživotnej funkcii rozhodla odísť do predčasného dôchodku. Napísala, že „už [nevie], čo má študentom a doktorandom povedať ohľadne toho, ako sa vysporiadať s tým ŠIALENSTVOM v oblasti klimatológie“. V&nbsp;rozhovore z roku 2017 Curryová povedala: „Akonáhle pochopíte vedecké neistoty, súčasná politická cesta, na ktorej sme sa ocitli, nedáva veľký zmysel. ... Musíme politickému dialógu poskytnúť väčší priestor pre riešenie. Snažím sa len otvoriť dialóg a prinútiť ľudí k zamysleniu.“<a href="#_edn54" id="_ednref54">[54]</a></p>
        <p>Roger Pielke mladší., profesor na Univerzite v Colorade, uviedol, že skúsenosť Curryovej ukazuje, že „mať doživotnú funkciu nie je zárukou akademickej slobody“.<a href="#_edn55" id="_ednref55">[55]</a> Pielke bol v minulosti vedeckým pracovníkom na univerzitnom Ústave pre výskum environmentálnych vied (CIRES). Hoci súhlasil s väčšinou záverov v „konsenze“ IPCC, bol vystavený podobným tlakom, pretože zdôraznil, že dáta nepotvrdzujú myšlienku, že počet extrémnych poveternostných udalostí, ako sú hurikány, tornáda a suchá, narástol kvôli emisiám skleníkových plynov. Nakoniec prešiel do Strediska pre správu športov Coloradskej univerzity.<a href="#_edn56" id="_ednref56">[56]</a></p>
        <p>Niet divu, že Joanne Simpsonová, členka Národnej akadémie inžinierstva a známa atmosférická vedkyňa NASA, vyjadrila svoj skepticizmus ohľadom „konsenzu“ až po odchode do dôchodku. „Pretože už nie som spojená so žiadnou organizáciou, ani nedostávam žiadne finančné prostriedky, môžem hovoriť celkom úprimne,“ uviedla. „Ako vedkyňa zostávam skeptická. ... Hlavný základ tvrdenia, že uvoľňovanie skleníkových plynov človekom, je príčinou otepľovania, spočíva takmer výlučne na klimatických modeloch. Všetci však vieme o krehkosti modelov týkajúcich sa systému atmosféry a&nbsp;povrchu zeme.“<a href="#_edn57" id="_ednref57">[57]</a></p>
        <h2>d) Propaganda a&nbsp;zastrašovanie</h2>
        <p>Roy Spencer, klimatológ a bývalý expert na satelity v NASA, v knihe <em>Veľký omyl globálneho otepľovania: Ako matka príroda oklamala najlepších svetových vedcov v oblasti klímy</em> (The Great Global Warming Blunder: How Mother Nature Fooled the World’s Top Climate Scientists) vymenoval zoznam pätnástich propagandistických techník používaných environmentalistami, medzi ktoré patrí vyvolávanie paniky, odvolávanie sa na autority, podporovanie mentality stáda, používanie osobných útokov, vytváranie stereotypov, senzácií a falšovanie záznamov.<a href="#_edn58" id="_ednref58">[58]</a></p>
        <p>Britský novinár Brendan O’Neill v článku „Klíma cenzúry“ z roku 2016 napísal o posmešnej rétorike, ktorej ľudia v&nbsp;mnohých krajinách čelia, ak sa opovážia pochybovať o prevládajúcej&nbsp; teórii zmeny klímy. Jeden britský diplomat napríklad vo verejnom prejave uviedol, že s tými, ktorí pochybujú o zmene klímy, by sa v médiách malo zaobchádzať rovnako ako s teroristami a že by ich názorom nemal byť poskytnutý žiaden priestor. Bývalý výkonný riaditeľ veľkej environmentálnej skupiny varoval, že médiá by sa mali pred zverejňovaním názorov skeptikov zmeny klímy dvakrát zamyslieť, pretože „umožnenie šírenia takýchto dezinformácií by spôsobilo škodu“.<a href="#_edn59" id="_ednref59">[59]</a></p>
        <p>Niektorí sa dokonca pokúsili využiť právne prostriedky na pozastavenie slobody slova, aby umlčali hlasy odporcov hypotézy o&nbsp;otepľovaní klímy. Na summite v Austrálii, ktorého sa zúčastnili významní politici vrátane premiéra, bol predložený návrh na to, aby „popierači“ boli zbavení občianstva: jedným z návrhov bolo opätovne preveriť austrálskych občanov a vydať občianstvo iba tým, u&nbsp;ktorých sa overilo, že sú „šetrní k životnému prostrediu a klíme“.<a href="#_edn60" id="_ednref60">[60]</a> V&nbsp;roku 2015 zaslalo dvadsať akademikov list americkému prezidentovi a&nbsp;ministrovi spravodlivosti, v ktorom žiadali, aby bol zákon o vydieračských, vplyvových a skorumpovaných organizáciách (Racketeer Influenced and Corrupt Organizations Act) použitý na&nbsp;vyšetrovanie „spoločností a ďalších organizácií, ktoré vedome klamali Američanov ohľadom rizík spojených so zmenou klímy“, a dodali, že „priestupky“ týchto organizácií sa musia „čo najskôr zastaviť“.<a href="#_edn61" id="_ednref61">[61]</a></p>
        <p>Tí, ktorí sa k teórii o zmene klímy stavajú skepticky, sú označovaní za „popieračov“. Patria sem rôzne skupiny ľudí a jednotlivci, od tých, ktorí uznávajú otepľovanie klímy, ale sú toho názoru, že sa s tým dokážeme vyrovnať, až po tých, ktorí otepľovanie ako vedecký jav popierajú úplne. Dôsledky, ktoré so sebou nesie nálepka „popierač“, sú značné. Charles Jones, profesor angličtiny na dôchodku z Edinburskej univerzity, uviedol, že tento termín je použitý tak, aby skeptikov staval na rovnakú úroveň morálnej skazenosti ako popieračov holokaustu. Podľa O’Neilla niektorí ľudia dokonca tvrdia, že skeptici voči teórii zmeny klímy sú spolupáchateľmi v nadchádzajúcom ekologickom holokauste a môžu v budúcnosti čeliť súdom v&nbsp;norimberskom štýle. Citoval istého „ekofejtónistu“: „Hovorím si, aké rozsudky by sudcovia mohli vyniesť na budúcich medzinárodných trestných tribunáloch nad tými, ktorí budú v nasledujúcich desaťročiach čiastočne, avšak priamo zodpovední za milióny úmrtí v&nbsp;dôsledku vyhladovania, hladomoru a chorôb. Zaradil som [ich popieranie zmeny klímy] do podobnej morálnej kategórie, ako je popieranie holokaustu – až na to, že tentoraz holokaust ešte len príde a stále máme čas sa mu vyhnúť. Tí, ktorí sa snažia o to, aby sme sa mu nevyhli, sa jedného dňa budú musieť zodpovedať za svoje zločiny.“</p>
        <p>O’Neill to vo svojom článku okomentoval: „Zvyčajne sa myšlienky alebo slová rovnajú&nbsp; zločinom iba v&nbsp;autoritárskych štátoch, kde diktátori hovoria o ,ideozločinoch‘ a ich hrozbe pre spoločenský systém. ... Od démonizovania určitej skupiny ľudí a&nbsp;odsúdenia ich argumentov, ktoré sa označia za toxické a nebezpečné, je to už len krok k požadovaniu väčšej a tvrdšej cenzúry.“<a href="#_edn62" id="_ednref62">[62]</a> Tento postreh je presný. Obmedzenie práva slobodne zmýšľať je jedným zo spôsobov, ako komunizmus ľudí oddeľuje od pojmov dobra a zla, ktoré sú založené na univerzálnych hodnotách.</p>
        <h2>3. Komunistický environmentalizmus</h2>
        <p>V minulých desaťročiach boli komunistické sily na ústupe a&nbsp;zároveň vyšli najavo politické a ekonomické katastrofy komunistických režimov. Preto sa komunizmus, aby presadil svoje ciele, chopil environmentalizmu.</p>
        <h2>a) Obviňovanie kapitalizmu</h2>
        <p>Environmentalizmus považuje kapitalizmus za svojho nepriateľa, to majú s komunizmom spoločné. Keď sa komunizmus stretol s nezdarom ohľadom robotníckeho hnutia vo vyspelých západných krajinách, zmenil smer a prisvojil si environmentalistické ciele. Normálny aktivizmus za ochranu životného prostredia sa premenil v&nbsp;aktivizmus zameraný na porazenie kapitalizmu.</p>
        <p>Komunistická doktrína pôvodne presadzovala utópiu&nbsp; „neba na zemi“, aby podnietila vzburu a zvrhla existujúci sociálny systém. Komunizmus pod rúškom environmentalizmu prijal podobný prístup, s tým rozdielom, že vízia, ktorú opisuje, je presným opakom. Namiesto úžasnej robotníckej utópie ľudstvu predkladá desivú dystopickú víziu „pekla na zemi“. Podľa tohto scenára bude v priebehu niekoľkých desaťročí prežitie ľudstva ohrozené globálnym otepľovaním, zosuvmi pôdy, tsunami, suchom, záplavami a&nbsp;vlnami horúčav. Cieľovou skupinou tohto hnutia nie sú chudobní, ale bohatí, od ktorých sa očakáva, že opustia svoj súčasný životný štýl.</p>
        <p>Podľa pôvodných doktrín komunizmu ihneď potom, čo prevezmú komunisti moc, prvým krokom je zabaviť bohatým majetok, za údajným účelom jeho prerozdelenia chudobným. V skutočnosti ale chudobní zostávajú chudobnými, zatiaľ čo všetko bohatstvo končí v rukách skorumpovaných úradníkov. Ďalším krokom je vytvorenie štátom riadenej ekonomiky a zrušenie súkromného vlastníctva. Takto sa zničí národná ekonomika a každý je odsúdený k životu v útrapách.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
        <p>Rovnaká rovnica sa používa na medzinárodnej úrovni. Požaduje sa, aby bohaté krajiny poskytovali pomoc chudobnejším štátom – dochádza teda k prerozdeleniu bohatstva v celosvetovom meradle. V skutočnosti chudobné krajiny zostávajú chudobné, pretože peniaze určené na ich rozvoj zvyčajne končia v rukách skorumpovaných úradníkov týchto krajín. Zodpovednosť vlády je medzitým rozšírená a&nbsp;trhové mechanizmy sú nahradené direktívnou ekonomikou, používajúc najrôznejšie drakonické environmentálne opatrenia, ktoré bránia normálnemu fungovaniu kapitalizmu a nútia podniky, aby zatvorili svoje prevádzky alebo ich premiestnili do zahraničia, čím sa oslabí ekonomika krajiny. Prostredníctvom týchto metód sa environmentálne hnutie snaží ochromiť kapitalizmus. Environmentálne predpisy sa stali dôležitými nástrojmi pre podkopávanie kapitalistických ekonomík a je známe, že ničia viac pracovných miest, než vytvárajú.</p>
        <p>Cieľom súčasného environmentalizmu je šíriť strach z&nbsp;budúcich katastrof a držať verejnosť a vlády ako rukojemníkov tohto strachu. Mnohí z tých, ktorí aktívne propagujú tieto katastrofické scenáre, však žijú luxusným životným štýlom, majú veľkú spotrebu energie a zanechávajú veľkú uhlíkovú stopu. Títo ľudia si evidentne nemyslia, že by na obzore bola nejaká katastrofa.</p>
        <p>Aby bolo možné využiť strach z krízy, najmä pomocou globálneho otepľovania ako „spoločného nepriateľa“, a spojiť sily, aby sa postavili proti kapitalizmu, je pre environmentalistov nevyhnutné zdôrazniť a zveličiť povahu údajnej krízy. Najjednoduchším spôsobom je vyvolať u verejnosti strach z&nbsp;využitia najlacnejších zdrojov energie, t. j. fosílnych palív – uhlia, ropy, zemného plynu – a tiež jadrovej energie. Environmentalistom sa pred desiatkami rokov podarilo prinútiť ľudí, aby sa obávali jadrovej energie, a teraz sa snažia ľudí prinútiť, aby sa báli používať fosílne palivá, tvrdiac, že fosílne palivá vedú ku katastrofálnemu globálnemu otepľovaniu.</p>
        <p>V skutočnosti však veda o klíme nedospela k záveru, že globálne otepľovanie je spôsobené ľudskou činnosťou alebo že globálne otepľovanie rozhodne povedie ku katastrofe. Ak za zmenou klímy stoja prirodzené príčiny, potom všetky tieto vládne politiky slúžia iba na to, aby brzdili hospodársky rozvoj, pričom často poskytujú iba marginálne výhody.</p>
        <p>Napríklad úradníci zvyšujú štandardy emisných noriem pre automobily s odôvodnením, že to znižuje uhlíkovú stopu. To však prirodzene znamená vyššie výrobné náklady a menší zisk, po ktorom nasleduje väčšia nezamestnanosť a outsourcing priemyslu do rozvojových krajín s nižšími nákladmi. Navyše zvýšenie palivovej účinnosti všetkých automobilov z 35,5 míle (57,1 km) na galón v roku 2016 na 54,5 míle (87,7 km) na galón do roku 2025 by znížilo do roku 2100 rozsah globálneho otepľovania najviac o 0,02°C.<a href="#_edn63" id="_ednref63">[63]</a> To by teda v skutočnosti globálne otepľovanie vôbec znížiť nepomohlo. Rôzne obmedzenia pochybnej účinnosti stáli milióny pracovníkov ich miesta a ťažko zasiahli výrobný priemysel, výskumné inštitúcie, energetické inovácie a medzinárodnú konkurencieschopnosť západných krajín.</p>
        <p>Zástancovia ochrany životného prostredia nadšene podporujú zelenú energiu, predovšetkým solárnu energiu a výrobu veternej energie. Bohužiaľ znečistenie, ktoré ide ruka v ruke s vytváraním zelenej energie, je buď podceňované, alebo jednoducho zakrývané pred zrakmi verejnosti. Pri výrobe solárnych panelov sa ako vedľajší produkt vytvára smrteľne jedovatý chlorid kremičitý, ktorý má na svedomí ďalšie environmentálne problémy. Správa <em>Washington Post</em> cituje Žen Ping-jena, profesora na fakulte vedy o materiáloch z Chepejskej technologickej univerzity: „Pôda, do ktorej ho [chlorid kremičitý] vyhodíte alebo zakopete, bude neúrodná. Na mieste nebude rásť žiadna tráva ani stromy. ... Je to ako dynamit – je jedovatý a znečisťuje. Ľudia sa ho nemôžu nikdy dotknúť.“<a href="#_edn64" id="_ednref64">[64]</a> Výroba solárnych panelov spotrebováva obrovské množstvo konvenčnej energie, vrátane uhlia a ropy.</p>
        <p>Podľa Parížskej dohody musia rozvinuté krajiny do roku 2025 poskytnúť každý rok sto miliárd dolárov, aby pomohli rozvojovým krajinám znížiť emisie a „prispôsobiť sa klimatickým zmenám“. Ak by Spojené štáty od dohody neodstúpili, museli by do roku 2025 znížiť svoje emisie skleníkových plynov o 26 až 28 % oproti úrovni v roku 2005. To znamená, že Spojené štáty by museli každý rok znížiť emisie o 1,6 miliardy ton. Pokiaľ ide o Čínu, najväčšieho znečisťovateľa na svete, dohoda jej umožňuje pokračovať v&nbsp;náraste emisií uhlíka do roku 2030.<a href="#_edn65" id="_ednref65">[65]</a></p>
        <p>Vo vyhlásení, ktoré formálne oznamuje odstúpenie od dohody, prezident Donald Trump uviedol, že dodržiavanie predpisov by do roku 2025 mohlo USA stáť 2,7 milióna pracovných miest, pričom citoval štúdiu ekonomicko-poradenskej spoločnosti National Economic Research Associates.</p>
        <p>Prezident uviedol, že štúdia tiež predpovedá, že dodržiavanie predpisov by do roku 2040 znížilo produkciu v týchto odvetviach: výroba papiera, ktorého produkcia by klesla o 12 %, výroba cementu o 23 %, produkcia železa a ocele o 38 %, uhlie o 86 % a zemného plynu o 31 %.</p>
        <p>„Ekonomiku by to stálo takmer 3 bilióny dolárov z HDP, prišli by sme o 6,5 milióna pracovných miest v priemysle, zatiaľ čo domácnosti by mali o 7 000 dolárov nižší [ročný] príjem, a v mnohých prípadoch ešte oveľa nižší,“ uviedol Trump. „V skutočnosti by 14-dňové emisie uhlíka len z&nbsp;Číny samotnej zmazali prínosy ... očakávaného amerického zníženia [emisií] v roku 2030 – po tom, čo by sme museli minúť miliardy a miliardy dolárov, prišli o pracovné miesta, zavreli továrne a&nbsp;boli zaťažení oveľa vyššími energetickými nákladmi pre naše podniky a pre naše domácnosti.“<a href="#_edn66" id="_ednref66">[66]</a></p>
        <p>Rozmach environmentálneho hnutia zahral do karát komunistickým krajinám v ich boji proti Západu. Nezmyselné predpisy a dohody však v západných kapitalistických krajinách dusia priemysel, ekonomiku a technologický vývoj. To bráni Amerike v jej úlohe vedúcej&nbsp; superveľmoci a bašty slobody proti komunizmu.</p>
        <p>Nepopierame, že životné prostredie potrebuje ochranu. Cieľ ochrany životného prostredia by však mal byť v rovnováhe s&nbsp;potrebami ľudstva. Chrániť životné prostredie kvôli ochrane samotnej je prehnané a ľudstvo sa stáva obeťou tejto snahy. Túto iniciatívy navyše využili komunistické prvky. Dnešný environmentalizmus sa nestará o rovnováhu a stal sa extrémistickou ideológiou. Mnohí environmentalisti majú nepochybne dobré úmysly. V snahe zmobilizovať a sústrediť zdroje štátu na splnenie svojich cieľov však spolupracujú s komunizmom.</p>
        <h2>b) Environmentalizmus ako nové náboženstvo</h2>
        <p>Spisovateľ Michael Crichton, autor knihy <em>Jurský park</em>, raz povedal, že environmentalizmus sa dnes stal jedným z najmocnejších náboženstiev v západnom svete. Nesie podľa neho typické rysy náboženstva: „Je v ňom rajská záhrada, stav milosti a jednoty s prírodou, je tam upadnutie do nemilosti znečistenia ako dôsledok ochutnania ovocia zo stromu poznania a je tam aj posledný súd ako dôsledok našich činov. Všetci sme energetickí hriešnici, odsúdení zomrieť, ak nebudeme hľadať spasenie, ktoré sa teraz nazýva udržateľnosť. Udržateľnosť je spása v&nbsp;náboženstve zvanom životné prostredie.“</p>
        <p>Crichton sa domnieva, že všetky presvedčenia environmentalistov sú založené na slepej viere, ktorej dávajú prednosť pred faktami: „Stále viac sa zdá, že fakty nie sú potrebné, pretože princípy environmentalizmu sú všetky o viere. Ide o to, či budete hriešnikom, alebo [budete] spasení. Či budete jedným z ľudí na strane spasenia, alebo na strane skazy. Či budete jedným z nás, alebo jedným z nich.“<a href="#_edn67" id="_ednref67">[67]</a></p>
        <p>Rad odborníkov s jeho názorom súhlasí. William Cronon, vplyvný historik v oblasti&nbsp; životného prostredia v Spojených štátoch verí, že environmentalizmus je náboženstvom, pretože navrhuje komplexný súbor mravných požiadaviek, podľa ktorých možno súdiť ľudské správanie.<a href="#_edn68" id="_ednref68">[68]</a> Freeman Dyson, renomovaný vedec a&nbsp;kvantový mechanik, 12. júna 2008 uviedol v článku časopisu <em>New York Review of Books</em>, že environmentalizmus je „celosvetové svetské náboženstvo“, ktoré „nahradilo socializmus ako vedúce sekulárne náboženstvo“. Toto náboženstvo environmentalizmu zastáva názor, že „plieniť planétu odpadovými produktmi nášho luxusného života je hriech a tou spravodlivou cestou má byť čo najšetrnejší život“. Etiku tohto nového náboženstva, ako vysvetlil, „sa učia deti v škôlkach, školách a na univerzitách po celom svete“.<a href="#_edn69" id="_ednref69">[69]</a></p>
        <p>Mnoho ochrancov životného prostredia sa tejto téme nevyhýba. Bývalý šéf IPCC, ktorý rezignoval po škandále so sexuálnym obťažovaním, naznačil vo svojom rezignačnom liste, že jeho náboženstvom je environmentalizmus.<a href="#_edn70" id="_ednref70">[70]</a></p>
        <p>Ako environmentalizmus získava stále väčší ideologický a&nbsp;náboženský charakter, stáva sa čím ďalej tým menej tolerantný voči iným názorom. Bývalý český prezident a ekonóm Václav Klaus verí, že environmentálne hnutie je teraz viac poháňané ideológiou než skutočnou vedou, a stáva sa tak kvázi-náboženstvom, ktorého cieľom je zničiť existujúcu spoločnosť. Toto nové náboženstvo rovnako ako komunizmus predkladá nádherný obraz utópie, ktorý možno dosiahnuť použitím ľudskej múdrosti na naplánovanie životného prostredia a&nbsp;záchranu sveta. Táto „spása“ je založená na opozícii voči existujúcej civilizácii.</p>
        <p>Klaus, ktorý napísal knihu <em>Modrá, nie zelená planéta</em>. <em>Čo je ohrozené: klíma, alebo sloboda?</em> (v anglickom preklade vyšla pod názvom Blue Planet in Green Shackles, pozn. prekl.), uviedol v jednom svojom prejave: „Ak vezmeme úvahy ochrancov životného prostredia vážne, zistíme, že&nbsp; ide o&nbsp;antihumánnu ideológiu.“ Súhlasil s biológom Ivanom Brezinom, že environmentalizmus nie je racionálnou, vedeckou odpoveďou na ekologické krízy, ale skôr že sa obmedzuje na celkové popretie civilizácie.<a href="#_edn71" id="_ednref71">[71]</a></p>
        <p>Okrem toho, že sa environmentalizmus stal pod vplyvom komunistických prvkov politickým hnutím, pôsobením týchto prvkov takisto získal podobu sekty stavajúcej sa proti ľudstvu.</p>
        <p>Kanadský politický komentátor Mark Steyn hovorí, že podľa environmentalistov „sme tým znečistením my a riešením je sterilizácia“. „Najlepší spôsob, ako získať udržateľnejšie životné prostredie pre naše deti, je nemať žiadne deti,“ tvrdia podľa Steyna environmentalisti. Uviedol príklad britskej ženy, ktorá išla na potrat a&nbsp;nechala sa sterilizovať, lebo verila, že mať deti je škodlivé pre životné prostredie.<a href="#_edn72" id="_ednref72">[72]</a></p>
        <p>Takéto zmýšľanie prikladá životnému prostrediu najvyššiu prioritu, vysoko nad posvätným postavením ľudských bytostí. Cieľ je dosahovaný aj za cenu kontroly ľudskej plodnosti a&nbsp;zbavovania ľudí ich vlastného práva na existenciu. Tento pohľad sa nelíši od pohľadu komunizmu a vo svojej podstate ide o antihumánnu ideológiu. Taktiež ide ruka v ruke s útokom ľavice na rodinu a tradičné role pohlaví.</p>
        <p>Kontrola populácie sa stala vhodnou metódou pre riešenie úpadku životného prostredia, kedy environmentálni aktivisti a&nbsp;ďalší socialisti propagujú potratové a antinatalistické politické stratégie a dokonca chvália brutálnu politiku jedného dieťaťa ČKS.</p>
        <p>Náboženský zápal, vynútená dogma, akcie proti kapitalizmu a kladenie ľudstva na nižšiu úroveň ako životné prostredie, nepovedie k zdravšiemu prírodnému prostrediu, a&nbsp;tým menej k spravodlivejšej a férovejšej ľudskej spoločnosti. Pokiaľ ide o ciele radikálneho environmentalizmu, stačí sa pozrieť na katastrofy, ktoré boli spôsobené komunistickým riadením v minulom storočí, aby sme predpovedali výsledok.</p>
        <h2>c) Politická infiltrácia: vytvorenie celosvetovej vlády</h2>
        <p>Je ťažké politicky presadiť komunizmus v&nbsp;demokratickom západnom svete, ktorý si cení individuálne práva, súkromné vlastníctvo, právny štát a voľný trh. V radikálnom environmentálnom hnutí sa vyžaduje moc vlády, ktorá prinúti ľudí k tomu, aby sa rozlúčili so svojím majetkom a životom v komforte a hojnosti.</p>
        <p>Z pohľadu radikálnych environmentalistov nie je vláda jedného národa schopná toho, aby sa vyrovnala s nespočetnými environmentálnymi krízami, ktorým čelí celá planéta. S argumentom o&nbsp;údajnom konsenze v&nbsp;otázkach, ako je človekom spôsobená zmena klímy, volajú títo environmentalisti po poverení OSN alebo po vytvorení nejakej inej globálnej autority.</p>
        <p>Ak sa nedokáže presadiť samotné hnutie, môže sa naďalej rozvíjať vízia bezprostrednej ekologickej krízy. Tak sa rozdúcha panika a strach, čo je potrebné na ovplyvnenie verejnosti a vlád, ktoré potom akceptujú násilne implementované environmentálne opatrenia. Tým sa následne dosiahne cieľ zničenia kapitalizmu a zavedenia komunizmu.</p>
        <p>Komunistické štáty tradične prerozdeľovali bohatstvo prostredníctvom revolúcie. V priebehu rokov však začal byť tento prístup čím ďalej ťažší. Environmentalisti preto prijali nepriame stratégie, ktoré nútia ľudí, aby sa potichu vzdali slobody a majetku v&nbsp;mene predchádzania environmentálnej katastrofe.</p>
        <p>Usporiadateľ kampane pre organizáciu Friends of the Earth (Priatelia Zeme) raz na konferencii OSN uviedol: „Jadrom našej odpovede na zmenu klímy musí byť prerozdelenie bohatstva a zdrojov.“<a href="#_edn73" id="_ednref73">[73]</a> Jeden z hlavných ekologických mysliteľov z Westminsterskej univerzity raz povedal reportérovi, že uhlíkové prídely „sa na ľudí musia uvaliť, či sa im to páči, alebo nie“ a&nbsp;že „demokracia je menej dôležitým cieľom, než je ochrana planéty pred vymiznutím života, pred koncom života na nej“.</p>
        <p>V „bitke“ proti zmene klímy bolo Spojené kráľovstvo prvou krajinou, ktorá prišla s koncepciou individuálnych uhlíkových prídelových lístkov. Jeden britský vedec o tom uvažoval ako o „zavedení druhej meny, s tým, že každý má rovnaký prídel – prerozdeľovania bohatstva tým, že človek si musí kupovať uhlíkové kredity od niekoho, kto sa má horšie ako on“.</p>
        <p>Ľudia, ktorí žili v Sovietskom zväze alebo komunistickej Číne, môžu v tomto druhu uhlíkových prídelov ľahko rozpoznať ďalšiu metódu použitú na zavedenie totalitného systému. V Číne sa kedysi na nákup nevyhnutných vecí ako je kuchynský olej, obilie a&nbsp;textilné látky, používali potravinové poukážky. Prostredníctvom prídelu potravín prebiehalo prerozdelenie bohatstva, zatiaľ čo ústredná vláda získala najvyššiu kontrolu nad majetkami ľudí a ich slobodou.</p>
        <p>Environmentalisti tiež používajú vlastnú ideológiu na&nbsp;obmedzenie slobody jednotlivcov. V západných krajinách sa vytvorenie vízie hroziacej environmentálnej katastrofy stalo pohodlným prostriedkom k tomu, ako presvedčiť ľudí, aby sa vzdali svojich práv. Austrálske združenie Carbon Sense Coalition navrhlo zoznam nových zákonov, ktoré by donútili ľudí, aby v mene riešenia globálneho otepľovania zmenili svoje správanie. Zoznam zahŕňal nasledujúce návrhy:</p>
        <p>zákaz otvoreného ohňa a&nbsp;krbových kachieľzákaz klasických žiaroviekzákaz balenej vodyzákaz osobných automobilov v niektorých oblastiachzákaz plazmových TVzákaz výstavby nových letískzákaz rozširovania existujúcich letískzákaz pohotovostného („stand-by“) režimu na spotrebičochzákaz výroby uhoľnej energiezákaz systémov s elektrickým ohrevom teplej vodyzákaz jazdiť autom v rámci dovolenkyzákaz trojdňových víkendovzdanenie detízdanenie veľkých áutzdanenie parkovacích plôch supermarketovzdanenie odpadkovzdanenie druhého domuzdanenie druhého autazdanenie prázdninových letovzdanenie elektriny, dotovanie slnečnej energiezdanenie showroom-ov pre veľké autáekologická daň pre autá vchádzajúce do miestnutnosť mať povolenie na riadenie automobilu za hranicou emisného limitu daného mestaobmedzenie výberu spotrebičovzavedenie uhlíkových kreditov pre každého človekazavedenie noriem palivovej účinnostipreskúmanie, ako znížiť objem metánu produkovaného nórskymi losmiodstránenie bielych čiar na cestách, aby motoristi jazdili opatrnejšie<a href="#_edn74" id="_ednref74">[74]</a></p>
        <p>Environmentalizmus sa tiež používa na zväčšenie veľkosti a&nbsp;právomocí vlády. Rôzne západné krajiny majú nielen obrovské agentúry na ochranu životného prostredia, ale tiež používajú životné prostredie ako výhovorku na založenie nových vládnych agentúr a rozšírenie právomocí agentúr existujúcich. Všetky agentúry majú byrokratickú tendenciu sebazáchovy a expanzie. Environmentálne agentúry nie sú výnimkou. Zneužívajú moc vo svojich rukách na šírenie naratívu o environmentálnej katastrofe, ktorý potom presadzujú medzi širokou verejnosťou, aby tak získali viac finančných prostriedkov a zabezpečili si svoje pozície vo vládnej štruktúre. Všetko to samozrejme zaplatia daňoví poplatníci.</p>
        <p>San Francisco vytvorilo pracovnú pozíciu klimatického vedúceho s ročným platom 160 000 dolárov. Jedna z najchudobnejších štvrtí v Londýne, Tower Hamlets, mala v&nbsp;jednom období päťdesiatosem oficiálnych pozícií súvisiacich so zmenou klímy.<a href="#_edn75" id="_ednref75">[75]</a> Logika je rovnaká ako tá, ktorú používajú univerzity a firmy pri prijímaní ľudí zodpovedných za „diverzitu“.</p>
        <p>Environmentalizmus sa využíva ako argument pre tvrdenie, že demokracia je zastaraná, a&nbsp;zároveň sa presadzuje zriadenie nadnárodnej alebo dokonca globálnej totalitnej vlády. Environmentalisti tvrdia, že demokracie nemôžu zvládnuť nadchádzajúcu environmentálnu krízu. Namiesto toho, aby sme prekonali výzvy, ktoré nás čakajú, musíme prijať totalitné alebo autoritárske formy vlády, či aspoň niektoré jej aspekty.<a href="#_edn76" id="_ednref76">[76]</a></p>
        <p>Autorka Janet Biehlová toto uvažovanie zhrnula tak, že podľa environmentalistov je „ekologická kríza riešiteľná iba totalitnými prostriedkami“,&nbsp; a&nbsp;tak „je&nbsp; potrebné,&nbsp; aby&nbsp; vznikla ,ekologická diktatúra‘“.<a href="#_edn77" id="_ednref77">[77]</a> Uvádza, že žiadna slobodná spoločnosť by neurobila to, čo vyžaduje zelená agenda.</p>
        <p>Paul R. Ehrlich, jeden zo zakladateľov environmentalizmu, napísal v knihe <em>Ako prežiť: Plán na záchranu kozmickej lode Zeme</em> (How to Be a Survivor: A Plan to Save Spaceship Earth):</p>
        <p><em>1. Kontrola pôrodnosti musí byť zavedená ako v krajinách nadmerne vyspelých, tak v krajinách málo vyspelých;</em></p>
        <p><em>2. Nadmerne vyspelé krajiny musia znížiť svoju vyspelosť;</em></p>
        <p><em>3. Málo vyspelé krajiny sa musia stať polo-vyspelými;</em></p>
        <p><em>4. Musia byť vytvorené monitorovacie postupy a&nbsp;regulovanie svetového systému v trvalom úsilí udržať optimálnu rovnováhu ľudí, zdrojov a životného prostredia.<a href="#_edn78" id="_ednref78">[78]</a></em></p>
        <p>V&nbsp;praxi, s výnimkou globálnej totalitnej vlády, by si žiadna vláda ani organizácia nemohla nakoniec takú moc zhromaždiť. Programy navrhnuté environmentalistami vo výsledku oslavujú komunistickú totalitu a naznačujú, že komunistický systém je najlepší.</p>
        <p>Agentúra Reuters v správe z roku 2007 odhadla, že kvôli politike jedného dieťaťa zavedenej v 80. rokoch&nbsp; 20. storočia, bola ČKS schopná obmedziť čínsku populáciu na 1,3 miliardy ľudí, čo je o 300 miliónov menej ako predpokladaných 1,6 miliardy. Autor správy poznamenal, že politika ČKS mala vedľajší účinok v tom, že prispela k zníženiu celosvetových emisií uhlíka. Správa však úplne ignorovala brutalitu, s ktorou bola presadzovaná totalitná politika. Vrátane nútených potratov, sterilizácií a&nbsp;ekonomického prenasledovania, rovnako ako traumu a utrpenie, ktorými trpeli milióny Číňaniek a ich rodín, ktorých základné práva a&nbsp;súkromie strana pošliapala.<a href="#_edn79" id="_ednref79">[79]</a></p>
        <p>Jedným z najväčších problémov ovplyvňujúcich životné prostredie je znečistenie. Čínsky model intenzívneho ekonomického rastu – napriek vykynoženiu stoviek miliónov ľudí z budúcich generácií – spotrebováva energiu obrovskou rýchlosťou. Čínska ľudová republika sa kvôli tomu stala najväčším znečisťovateľom na svete, ktorý má najviac znečistené ovzdušie vo veľkomestách a vážne znečistenú vodu. Vodu z&nbsp;väčšiny čínskych riek nie je bezpečné piť. Zamorený vzduch z Číny veje cez more do Kórey a Japonska, a&nbsp;dokonca prekračuje Tichý oceán, kde zasahuje americké západné pobrežie.</p>
        <p>Logicky by sa hlavným cieľom kritiky skutočných environmentalistov mala stať komunistická Čína, ale mnohí napodiv naopak ČKS chvália a dokonca ju považujú za nádej ohľadom ochrany životného prostredia. Webové stránky Komunistickej strany USA, People’s World, rozsiahlo informujú o ekologických problémoch. Hlavnou témou ich správ je tvrdenie, že environmentálna politika Trumpovej administratívy zničí Ameriku aj svet, zatiaľ čo ČKS je nádejou na záchranu.<a href="#_edn80" id="_ednref80">[80]</a></p>
        <p>Klaus vo svojej knihe napísal: „Environmentalizmus je hnutie, ktoré má v úmysle radikálne zmeniť svet bez ohľadu na následky (teda za cenu obmedzovania ľudskej slobody a na úkor&nbsp; ľudských životov). Má v úmysle zmeniť ľudstvo, ľudské správanie, štruktúru spoločnosti, systém hodnôt – jednoducho všetko!“<a href="#_edn81" id="_ednref81">[81]</a></p>
        <p>Klaus verí, že&nbsp; prístup environmentalistov k prírode je analógiou marxistickému prístupu k ekonomike: „Cieľom v obidvoch prípadoch je nahradiť slobodný, spontánny vývoj sveta (a ľudstva), potenciálne-optimálnym, ústredným alebo – s využitím dnešného módneho prívlastku – globálnym plánovaním svetového rozvoja. Rovnako ako v prípade komunizmu, je tento prístup utopický a viedol by k výsledkom, ktoré sa úplne líšia od zamýšľaných. Rovnako ako iné utópie, ani táto sa nikdy nemôže uskutočniť a snahy o jej zhmotnenie je možné uskutočniť iba obmedzením slobody prostredníctvom diktátu malej elitárskej menšiny nad drvivou väčšinou.“<a href="#_edn82" id="_ednref82">[82]</a></p>
        <h2>4. Nájdenie skutočného riešenia environmentálnej krízy</h2>
        <p>Boh stvoril ľudstvo a Zem. V tomto prostredí môžu ľudské bytosti žiť, prosperovať a množiť sa. Ľudia majú právo využívať prírodné zdroje a majú povinnosť chrániť prírodné zdroje a starať sa o životné prostredie. &nbsp;Po tisíce rokov mali ľudia na zreteli varovania, ktoré im nebesia v dávnych časoch zanechali, a&nbsp;žili v harmónii s prírodou.</p>
        <p>Výskyt environmentálnych problémov je dôsledkom úpadku morálky ľudí. V modernej dobe sa tento morálny úpadok ešte viac zväčšil kvôli moci vedy a techniky. Znečistené prírodné prostredie je iba vonkajším prejavom vnútorného morálneho znečistenia ľudstva. Pri očistení životného prostredia je potrebné začať očistením srdca.</p>
        <p>Nárast záujmu o životné prostredie pramení z ľudského pudu sebazáchovy. Je to&nbsp; prirodzené a pochopiteľné, ale stala sa z toho medzera, ktorú využil komunistický prízrak. Komunizmus sa prichytil na environmentalizmus, aby vytvoril rozsiahlu paniku, obhajoval pokrivený súbor hodnôt, pripravil ľudí o slobodu, pokúsil sa rozšíriť moc vlád a dokonca nastoliť svetovú vládu. Prijatie tejto alternatívnej formy komunizmu v snahe zachrániť životné prostredie môže viesť ku zotročeniu ľudstva.</p>
        <p>Vnútený politický program nie je odpoveďou na problémy životného prostredia, ktorým čelíme. Východiskom nie je ani spoliehanie sa na moderné technológie. Na vyriešenie krízy musíme získať hlbšie pochopenie vesmíru a prírody, ako aj vzťahu medzi ľuďmi a prírodou, a to pri zachovaní čestného a morálneho prístupu. Ľudstvo musí obnoviť svoje tradície, zlepšiť svoju morálku a nájsť cestu, ktorú pre neho nebesia stanovili. Keď to tak bude robiť, ľuďom sa prirodzene dostane božskej múdrosti a požehnania. Bude obnovený krásny a&nbsp;prirodzený svet, oplývajúci životom.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p><a href="#_ednref1" id="_edn1">[1]</a> Dong Zhongshu, Chunqiu fan lu, di Shiso [<em>Kronika jari a jesene</em>], fu zhi xiang, 14, <a href="https://ctext.org/chun-qiu-fan-lu/fu-zhi-xiang/zh"></a><a href="https://ctext.org/chun-qiu-fan-lu/fu-zhi-xiang/zh">https://ctext.org/chun-qiu-fan-lu/fu-zhi-xiang/zh</a>. Spomínaná pasáž sa objavuje vo formuláciách „天 之 生物 也, 以 养 人“ a „天地 之 生 萬物 也 以 養 人“. [V čínštine]</p>
        <p><a href="#_ednref2" id="_edn2">[2]</a> Confucius (Konfucius), <em>The Universal Order or Conduct of Life, a Confucian Catechism, (Being a Translation of One of the Four Confucian Books, Hitherto Known as the Doctrine of the Mean</em>), (Shanghai: Shanghai Evening Post &amp; Mercury Limited 1906), 68, <a href="https://bit.ly/2T74Dsb.">https://bit.ly/2T74Dsb.</a></p>
        <p><a href="#_ednref3" id="_edn3">[3]</a> Yi Zhou shu [<em>Stratená kniha Čou</em>], „Da jujie“, <a href="https://ctext.org/lostbook-of-zhou">https://ctext.org/lostbook-of-zhou</a> / da-ju / zh. [V čínštine]</p>
        <p><a href="#_ednref4" id="_edn4">[4]</a> Zengzi, citácie podľa: Li Ji [<em>Kniha obradov</em>], „Zhai Yi“ <a href="https://ctext.org/text.pl?">https://ctext.org/text.pl?</a> node = 61379 &amp; if = gb &amp; show = parallel. [V čínštine]</p>
        <p><a href="#_ednref5" id="_edn5">[5]</a> Wes Vernon, „The marxista Roots of the Global Warming Scare“,<em> Renew America</em>, 16. júna 2008, <a href="https://web.archive.org/web/20100724052619/"></a><a href="https://web.archive.org/web/20100724052619/">https://web.archive.org/web/20100724052619/</a>, <a href="http://www.renewamerica.com">http://www.renewamerica.com</a>:80/columns/vernon/080616.</p>
        <p><a href="#_ednref6" id="_edn6">[6]</a> Frederick Engels, „Notes and Fragments“, in: <em>Dialectics of Nature,</em> prekl. Clemens Dutt (Moscow: Progress Publishers, 1883), 295–311, navštívené cez Marxists Internet Archive 30. apríla 2020, 2018, https: // www.marxists.org/archive/marx/works/1883/don/ch07g.htm.</p>
        <p><a href="#_ednref7" id="_edn7">[7]</a> Brian Sussman, <em>Eco-Tyranny:</em> <em>How the Left</em>’<em>s Green Agenda Will Dismantle America</em> (Washington, D.C .: WND Books, 2012), 8–9.</p>
        <p><a href="#_ednref8" id="_edn8">[8]</a> Karl Marx, citácie podľa: Sussman, <em>Eco-Tyranny</em>, 10.</p>
        <p><a href="#_ednref9" id="_edn9">[9]</a> Na tom istom mieste, 11–15.</p>
        <p><a href="#_ednref10" id="_edn10">[10]</a> Maurice Strong, citácie podľa: Grace Baumgarten, <em>Cannot Be Silenced</em> (Grand Rapids, MI: WestBow Press, 2016)</p>
        <p><a href="#_ednref11" id="_edn11">[11]</a> Sussman, <em>Eco-Tyranny</em>, 35.</p>
        <p><a href="#_ednref12" id="_edn12">[12]</a> Natalie Grant Wrage, citácie podľa: Vernon, „The marxista Roots“.</p>
        <p><a href="#_ednref13" id="_edn13">[13]</a> John Bellamy Foster, „Marx’s Ecology in Historical Perspective “, <em>International Socialism Journal </em>96 (zima 2002), <a href="http://pubs.socialistreviewindex.org.uk/isj96/foster.htm.">http://pubs.socialistreviewindex.org.uk/isj96/foster.htm.</a></p>
        <p><a href="#_ednref14" id="_edn14">[14]</a> Ray Lankester, citácie podľa: Lewis S. Feuer, „The Friendship of Edwin Ray Lankester and Karl Marx: The Last Episode in Marx’s Intellectual Evolution“, <em>Journal of the History of Ideas </em>40, č. 4: 633–648.</p>
        <p><a href="#_ednref15" id="_edn15">[15]</a> James O’Connor, <em>Natural Causes: Essays in Ecological marxism</em> (New York: The Guilford Press, 1997).</p>
        <p><a href="#_ednref16" id="_edn16">[16]</a> Joel Kovel a Michael Lowy, „<em>The First Ecosocialist Manifesto“,</em> september 2001, stránka navštívená 30. apríla 2020, <a href="http://green.left.sweb.cz/frame/Manifesto.html.">http://green.left.sweb.cz/frame/Manifesto.html.</a></p>
        <p><a href="#_ednref17" id="_edn17">[17]</a> Bob Brown a Peter Singer, The Greens (Melbourne: Text Publishing Company, 1996), 55.</p>
        <p><a href="#_ednref18" id="_edn18">[18]</a> Mikhail Gorbachev (Michail Gorbačov), „We Have a&nbsp;Real Emergency“, <em>The New York</em> <em>Times,</em> 9. decembra 2009,</p>
        <p><a href="http://www.nytimes.com/2009/12/10/opinion/10iht-edgorbachev.html.">http://www.nytimes.com/2009/12/10/opinion/10iht-edgorbachev.html.</a></p>
        <p><a href="#_ednref19" id="_edn19">[19]</a> „Jack Mundey“, Sydney’s Aldermen, stránka navštívená 30. apríla 2020, <a href="http://www.sydneyaldermen.com.au/alderman/jack-mundey/.">http://www.sydneyaldermen.com.au/alderman/jack-mundey/.</a></p>
        <p><a href="#_ednref20" id="_edn20">[20]</a> Saul Alinsky, „Tactics“, in: <em>Rules for Radicals:</em> <em>A Practical Primer for Realistic Radicals</em> (New York: Vintage Books, 1971).</p>
        <p><a href="#_ednref21" id="_edn21">[21]</a> Zombie, „Climate Movement Drops Mask, Admits Communist Agenda“, <em>PJ Media</em>, 23. septembra 2014, <a href="https://pjmedia.com/zombie/2014/9/23/climate-movement-drops-mask-admits-communist-agenda.">https://pjmedia.com/zombie/2014/9/23/climate-movement-drops-mask-admits-communist-agenda.</a></p>
        <p><a href="#_ednref22" id="_edn22">[22]</a> Dan Barry a Al Baker, „For, Eco-Terrorism' Group, a Hidden Structure and Public Messages“, <em>The New York Times</em>, 8. januára 2001, https: // www.nytimes.com/2001/01/08/nyregion/for-eco-terrorism-group-a-hiddenstructure-and-public-messages.html.</p>
        <p><a href="#_ednref23" id="_edn23">[23]</a> Noel Moanda, „A Spark That Ignited a Flame: The Evolution of the Earth Liberation Front“, v <em>Igniting a Revolution</em>: <em>Voices in Defense of the Earth</em>, editovali Steven Best a Anthony J. Nocella, II (Oakland, CA: AK&nbsp; Press, 2006), 47.</p>
        <p><a href="#_ednref24" id="_edn24">[24]</a> Paul Watson, citácie podľa: Leslie Spencer, Jan Bollwerk a Richard C. Morais, „The Not So Peaceful World of Greenpeace“, <em>Forbes,</em> november 1991, sprístupnené skrze Heartland Institute, <a href="https://www.heartland.org/_template-assets/documents/publications/the_not_so_peaceful_world_of_greenpeace.pdf.">https://www.heartland.org/_template-assets/documents/publications/the_not_so_peaceful_world_of_greenpeace.pdf.</a></p>
        <p><a href="#_ednref25" id="_edn25">[25]</a> Ted Thornhill, „Humans Are NOT to Blame for Global Warming, Says Greenpeace Co-founder, as He Insists There Is ,No Scientific Proof' Climate Change Is manMade“, <em>Daily Mail,</em> 27. februára 2014, http: //www.dailymail.co.uk/sciencetech/article-2569215/Humans-notblame-global-warming-says-Greenpeace-founder-Patrick-Moore.html#ixzz2vgo2btWJ.</p>
        <p><a href="#_ednref26" id="_edn26">[26]</a> John Vidal, „Not Guilty: The Greenpeace Activists Who Used Climate Change as a Legal Defence“, <em>The Guardian,</em> 10. septembra 2008, <a href="https://www.theguardian.com/environment/2008/sep/11/activists.kingsnorthclimatecamp.">https://www.theguardian.com/environment/2008/sep/11/activists.kingsnorthclimatecamp.</a></p>
        <p><a href="#_ednref27" id="_edn27">[27]</a> Richard Lindzen, „The Climate Science Isn´t settled“, <em>The Wall Street Journal,</em> 30. novembra 2009, <a href="https://www.wsj.com/articles/SB10001424052748703939404574567423917025400.">https://www.wsj.com/articles/SB10001424052748703939404574567423917025400.</a></p>
        <p><a href="#_ednref28" id="_edn28">[28]</a> Steven E. Koonin, „Climate Science Is Not settled“, <em>The Wall Street Journal</em>, 19. septembra 2014, <a href="https://www.wsj.com/articles/climate-science-isnot-settled-1411143565.">https://www.wsj.com/articles/climate-science-isnot-settled-1411143565.</a></p>
        <p><a href="#_ednref29" id="_edn29">[29]</a> Steven Koonin, „A ,Red Team‘ Exercise Would Strengthen Climate Science,“ <em>The Wall Street Journal, </em>April 20, 2017, https: // www.wsj.com/articles/a-red-team-exercise-would-strengthen-climatescience-1492728579.</p>
        <p><a href="#_ednref30" id="_edn30">[30]</a> Michael Griffin, „NASA Chief Questions Urgency of Global Warming“, rozhovor od Stevea Inskeepa, <em>National Public Radio</em>, 31. mája 2007, <a href="https://www.npr.org/templates/story/story.php?storyId=10571499.">https://www.npr.org/templates/story/story.php?storyId=10571499.</a></p>
        <p><a href="#_ednref31" id="_edn31">[31]</a> Alicia Chang, „NASA Chief Regrets Remarks on Global Warming“, <em>NBC News,</em> 5. júna 2007, <a href="http://www.nbcnews.com/id/19058588/ns/us_news-environment/t/nasa-chief-regrets-remarksglobal-warming/.">http://www.nbcnews.com/id/19058588/ns/us_news-environment/t/nasa-chief-regrets-remarksglobal-warming/.</a></p>
        <p><a href="#_ednref32" id="_edn32">[32]</a> Michael Griffin, citácie podľa: Rebecca Wright, Sandra Johnson a Steven J. Dick, ed., NASA at 50: <em>Interviews With NASA' s Senior Leadership</em> (Washington, D.C .: Národný úrad pre letectvo a vesmír, 2009), 18, <a href="https://www.nasa.gov/sites/default/files/716218main_nasa_at_50-ebook.pdf.">https://www.nasa.gov/sites/default/files/716218main_nasa_at_50-ebook.pdf.</a></p>
        <p><a href="#_ednref33" id="_edn33">[33]</a> Lennart Bengtsson, citácie podľa: „Lennart Bengtsson Resigns: GWPF Voices Shock and Concern at the Extent of Intolerancia Within the Climate Science Community“, <em>The Global Warming Policy Foundation,</em> 5. mája 2014, <a href="http://www.thegwpf.org/lennart-bengtsson-resignsgwpf-voices-shock-and-concern-at-the-extent-of-intolerancie-withinthe-climate-science-community">http://www.thegwpf.org/lennart-bengtsson-resignsgwpf-voices-shock-and-concern-at-the-extent-of-intolerancie-withinthe-climate-science-community</a> /.</p>
        <p><a href="#_ednref34" id="_edn34">[34]</a> US Congress, US Congress, House, Committee on Science, Space and Technology of the United States House of Representatives, Hearing on Climate Science: Assumptions, Policy Implications and the Scientific Method, 29. marca 2017, <a href="https://docs.house.gov/meetings/SY/SY00">https://docs.house.gov/meetings/SY/SY00</a> / 20170329/105796 / HHRG-115-SY00-Wstate-CurryJ-20170329.pdf.</p>
        <p><a href="#_ednref35" id="_edn35">[35]</a> Na tom istom mieste.</p>
        <p><a href="#_ednref36" id="_edn36">[36]</a> Frederick Seitz, „Major Deception on Global Warming“, <em>The Wall Street Journal</em>, 12. júna 1996, <a href="https://www.wsj.com/articles/SB834512411338954000.">https://www.wsj.com/articles/SB834512411338954000.</a></p>
        <p><a href="#_ednref37" id="_edn37">[37]</a> Na tom istom mieste.</p>
        <p><a href="#_ednref38" id="_edn38">[38]</a> Tim Hingham, citácie podľa: Larry Bell, „The New York Times' Global Warming Hysteria Ignores 17 Years of Flat global Temperatures“, Forbes, 21. augusta 2013, <a href="https://www.forbes.com/sites/larrybell">https://www.forbes.com/sites/larrybell</a> / 2013/08/21 / the-new-york-times-global-warming-hysteriaignores-17-years-of-flat-global-temperatures /.</p>
        <p><a href="#_ednref39" id="_edn39">[39]</a> Paul Reiter, citácie podľa: Christopher C. Horner, <em>Red Hot Lies: How Global Warming Alarmists Use Threats, Fraud, and Deception to Keep You Misinformed</em> (Washington, D.C .: Regnery Publishing, 2008), 319.</p>
        <p><a href="#_ednref40" id="_edn40">[40]</a> US Congress, Senate, Committee on Commerce, Science, and Transportation, <em>Projected and Pasca Effects of Climate Change: A&nbsp;Focus on Marine and Terrestrial Systems</em>, 109th Cong., 2nd sess., 26. apríla 2006.</p>
        <p><a href="#_ednref41" id="_edn41">[41]</a> Bloomberg, citácie podľa: James Taylor, „Mosquitoes Ignore Global Warming Predictions“, <em>Forbes,</em> 5. októbra 2011, <a href="https://www.forbes.com/sites">https://www.forbes.com/sites</a> / jamestaylor / 2011/10/05 / mosquitoes-ignore-global-warmingpredictions / #20938da66c1b.</p>
        <p><a href="#_ednref42" id="_edn42">[42]</a> Leonard J. Bruce-Chwatt, „Malaria Research and Eradication in the USSR“, Buletin Svetovej zdravotníckej organizácie, 1959, sprístupnené cez National Institutes of Health, 30. apríla 2020, <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2537933/pdf/bullwho00505-0074.pdf.">https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2537933/pdf/bullwho00505-0074.pdf.</a></p>
        <p><a href="#_ednref43" id="_edn43">[43]</a> Zoë Corbyn, „Global Warming Wilts Malaria“, Nature, 21. decembra 2011, <a href="https://www.nature.com/news/global-warming-wiltsmalaria-1.9695.">https://www.nature.com/news/global-warming-wiltsmalaria-1.9695.</a></p>
        <p><a href="#_ednref44" id="_edn44">[44]</a> Christopher Landsee, citácie podľa: James Tylor, „Climate Scientist Quits IPCC, Blasts Politicized, Preconceived Agendas' “, <em>The Heartland Institute</em>, 1. apríla 2005, <a href="https://www.heartland.org/news-opinion/news">https://www.heartland.org/news-opinion/news</a> / climate-vedec-quits-IPCC-blasts-politicized-preconceivedagendas?source = policybot.</p>
        <p><a href="#_ednref45" id="_edn45">[45]</a> US Congress, Senate, Committee on Environment and Public Works, <em>Full Committee Hearing on Climate Change and the Media</em>, 109th Cong., 2nd sess., 6. decembra 2006, https: //www.epw.senate.gov/public/index.cfm/hearings?ID=BFE4D91D-802A-23AD-4306-B4121BF7ECED.</p>
        <p><a href="#_ednref46" id="_edn46">[46]</a> Jonathan Leake, „Wildlife Groups Axe Bellamy as Global Warming ,Heretic‘ “, <em>Sunday Times Online,</em> 15. mája 2005, https: //web.archive.org / web / 20080906161240 / http: //www.timesonline.co.uk/tol/news/uk/article522744.ece.</p>
        <p><a href="#_ednref47" id="_edn47">[47]</a> Horner, <em>Red Hot Lies</em>, 78–79.</p>
        <p><a href="#_ednref48" id="_edn48">[48]</a> Na tom istom mieste, 73–74.</p>
        <p><a href="#_ednref49" id="_edn49">[49]</a> Patrick J. Michaels a Robert C. Balling Jr., <em>Climate of Extremes: Global Warming Science They Do not Want You to Know</em> (Washington, D.C .: Cato Institute, 2009), x-xiii.</p>
        <p><a href="#_ednref50" id="_edn50">[50]</a> James Taylor, „Associate State Climatologist Fired for Exposing Warming Myths“, <em>The Heartland Institute,</em> 1. júna 2007, https: // www.heartland.org/news-opinion/news/associate-state-climatologist-firedfor-exposing-warming-myths.</p>
        <p><a href="#_ednref51" id="_edn51">[51]</a> Hilary Lawson, dir., <em>The Greenhouse Conspiracy</em> (UK: Channel 4 Television, 1990), zverejnené na YouTube užívateľom ZilogBob 16. februára 2015, <a href="https://www.youtube.com/watch?v=lvpwAwvDxUU.">https://www.youtube.com/watch?v=lvpwAwvDxUU.</a></p>
        <p><a href="#_ednref52" id="_edn52">[52]</a> Marc Morano, „Climate Skeptics Reveal ,Horror Stories‘ of Scientific Suppression“, US Senate Committee on Environment and Public Works, 6. marca 2008, <a href="https://www.epw.senate.gov/public/index.cfm/pressreleases-all?">https://www.epw.senate.gov/public/index.cfm/pressreleases-all?</a> ID = 865dbe39-802a-23ad-4949-ee9098538277</p>
        <p><a href="#_ednref53" id="_edn53">[53]</a> US Congress, Senate, Subcommittee on Space, Science and Competitiveness, „Data or Dogma? Promoting Open Inquiry in the Debate over the Magnitude of Human Impact on Climate Change“, 114th Cong., 2nd sess., 8. decembra 2015,</p>
        <p>https: //curryja.files.wordpress.com/2015/12/curry-senate-testimony-2015.pdf.</p>
        <p><a href="#_ednref54" id="_edn54">[54]</a> Scott Waldman, „Judith Curry Retires Citing ,Craziness‘ of Climate Science“, <em>E &amp; E</em> News, 4. januára 2017, <a href="https://www.eenews.net/stories">https://www.eenews.net/stories</a> / 1060047798.</p>
        <p><a href="#_ednref55" id="_edn55">[55]</a> Roger Pielke Jr., citácie podľa: Waldman, „Judith Curry Retires“.</p>
        <p><a href="#_ednref56" id="_edn56">[56]</a> Rich Lowry, „A Shameful Climate Witch Hunt“, <em>National Review Online,</em> 27. februára 2015,&nbsp; ://www.nationalreview.com/2015/02/shameful-climate-witch-hunt-rich-Lowry /.</p>
        <p><a href="#_ednref57" id="_edn57">[57]</a> US Congress, Senate, Committee on Environment and Public Works, US Senate Minority Report: <em>More Than 650 International Scientists Dissent Over Man-Made Global Warming Claims. Scientists Continue to Debunk ,Consensus' in 2008,</em> S. Rep., 11. decembra 2008, https: //www.epw.senate.gov/public/_cache/files/8/3/83947f5d-d84a-4a84-ad5d-6e2d71db52d9 / 01AFD79733D77F24A71FEF9DAFCCB056.senateminorityreport2.pdf.</p>
        <p><a href="#_ednref58" id="_edn58">[58]</a> Roy W. Spencer, <em>The Great Global Warming Blunder: How Mother Nature Fooled the World</em>’<em>s Top Climate Scientists</em> (New York: Encounter Books, 2010), 31.</p>
        <p><a href="#_ednref59" id="_edn59">[59]</a> Brendan O’Neill, „A Climate of Censorship“, <em>The Guardian,</em> 22. novembra 2006, <a href="https://www.theguardian.com/commentisfree/2006/nov">https://www.theguardian.com/commentisfree/2006/nov</a> / 22 / aclimateofcensorship.</p>
        <p><a href="#_ednref60" id="_edn60">[60]</a> Horner, <em>Red Hot Lies</em>, 107.</p>
        <p><a href="#_ednref61" id="_edn61">[61]</a> Hans von Spakovsky a Nicolas Loris, „The Climate Change Inquisition: An Abuse of Power that Offends the First Amendment and Threatens Informed Debate“, The Heritage Foundation, 24. októbra 2016, https: //www.heritage.org/report/the-climate-change-inquisition-abuse-poweroffends-the-first-amendment-and-threatens</p>
        <p><a href="#_ednref62" id="_edn62">[62]</a> O’Neill, „A Climate of Censorship“.</p>
        <p><a href="#_ednref63" id="_edn63">[63]</a> John Fund, „Rollback Obama’s CAFE Power Grab, Give Car Consumers Freedom“, <em>National Review,</em> 23. mája 2018, https: // www.nationalreview.com/corner/fuel-standards-cafe-epa-rolls-back/.</p>
        <p><a href="#_ednref64" id="_edn64">[64]</a> Ren Bingyan, citácie podľa: Ariana Eunjung Cha, „Solar Energy Firms Leave Waste Behind in China“, <em>The Washington Post</em>, 9. marca 2008, <a href="http://www.washingtonpost.com/wp-dyn">http://www.washingtonpost.com/wp-dyn</a> / content / article / 2008/03/08 / AR2008030802595.html? referrer = emailarticle &amp; noredirect = on.</p>
        <p><a href="#_ednref65" id="_edn65">[65]</a> „<em>The Paris Agreement on Climate Change“,</em> Natural Resources Defense Council (NRDC), december 2015, č. zl.: 15-11-Y, <a href="https://www.nrdc.org/sites">https://www.nrdc.org/sites</a> / default / files / paris-climate-agreement-IB.pdf.</p>
        <p><a href="#_ednref66" id="_edn66">[66]</a> Donald J. Trump, „Statement by President Trump on the Paris Climate Accord“, <em>The White House,</em> 1. júna 2017, <a href="https://www.whitehouse.gov/briefings-statements">https://www.whitehouse.gov/briefings-statements</a> / statement-president-trump-paris-climate-accord /.</p>
        <p><a href="#_ednref67" id="_edn67">[67]</a> Michael Crichton, „Environmentalisti Is a Religion: Remarks to the Commonwealth Club“, <em>Hawaii Free Press,</em> 15. septembra 2003, &nbsp;<a href="http://www.hawaiifreepress.com/ArticlesMain/tabid/56/ID/2818/Crichton-Environmentalisti-is-a-religion.aspx.">http://www.hawaiifreepress.com/ArticlesMain/tabid/56/ID/2818/Crichton-Environmentalisti-is-a-religion.aspx.</a></p>
        <p><a href="#_ednref68" id="_edn68">[68]</a> Robert H. Nelson, „New Religion of environmentalisti“, <em>Independent Institute</em>, 22. apríla 2010, <a href="http://www.independent.org/news/article.asp?">http://www.independent.org/news/article.asp?</a> id = 5081.</p>
        <p><a href="#_ednref69" id="_edn69">[69]</a> Freeman Dyson, „The Question of Global Warming“, <em>The New York Review of Books</em>, jún 2008, <a href="https://www.nybooks.com/articles">https://www.nybooks.com/articles</a> / 2008/06/12 / the-question-of-global-warming.</p>
        <p><a href="#_ednref70" id="_edn70">[70]</a> Damian Carrington, „IPCC Chair Rajendra Pachauri Resigns“, <em>The Guardian,</em> 24. februára 2015, <a href="https://www.theguardian.com/environment">https://www.theguardian.com/environment</a> / 2015 / feb / 24 / IPCC-chair-Rajendra-Pachauri-resigns.</p>
        <p><a href="#_ednref71" id="_edn71">[71]</a> Václav Klaus, „An anti-human ideology“, <em>Financial Post</em>, 20. októbra 2010, <a href="https://business.financialpost.com/opinion/vaclav-klaus-an-antihuman-ideology.">https://business.financialpost.com/opinion/vaclav-klaus-an-antihuman-ideology.</a></p>
        <p><a href="#_ednref72" id="_edn72">[72]</a> Mark Steyn, „Children? Not if you love the planet“, <em>The Orange County Register,</em> 14. decembra 2007, <a href="https://www.ocregister.com/2007/12/14/mark-Steyn-children-not-if-you-love-the-plan">https://www.ocregister.com/2007/12/14/mark-Steyn-children-not-if-you-love-the-plan</a>ét.</p>
        <p><a href="#_ednref73" id="_edn73">[73]</a> Emma Brindal, citácie podľa: Horner, <em>Red Hot Lies,</em> 214.</p>
        <p><a href="#_ednref74" id="_edn74">[74]</a> Na tom istom mieste, 211-215.</p>
        <p><a href="#_ednref75" id="_edn75">[75]</a> Na tom istom mieste, 227.</p>
        <p><a href="#_ednref76" id="_edn76">[76]</a> David Shearman a Joseph Wayne Smith, <em>The Climate Change Challenge and the Failure of Democracy </em>(Westport, CT: Praeger, 2007).</p>
        <p><a href="#_ednref77" id="_edn77">[77]</a> Janet Biehl, citácie podľa: Horner, <em>Red Hot Lies,</em> 219-220.</p>
        <p><a href="#_ednref78" id="_edn78">[78]</a> Paul Ehrlich, citácie podľa: Václav Klaus, <em>Modrá, nikoli zelená planeta; Co je ohroženo: klima nebo svoboda? &nbsp;</em>(Dokořán, 2007), 32.</p>
        <p><a href="#_ednref79" id="_edn79">[79]</a> Alister Doyle, „China Says One-Child Policy Helps Protect Climate“, <em>Reuters,</em> 30. augusta 2007,<a href="https://www.reuters.com/article/environment-climate-population-dc-idUSKUA07724020070831.">https://www.reuters.com/article/environment-climate-population-dc-idUSKUA07724020070831.</a></p>
        <p><a href="#_ednref80" id="_edn80">[80]</a> John Bachtell, „China Builds an, Ecological Civilization While the World Burns“, <em>People</em> <em>'s world</em>, 21. augusta 2018, https: //www.peoplesworld.org / article / china-Builds-an-ecological-civilization-while-the-worldburns /.</p>
        <p><a href="#_ednref81" id="_edn81">[81]</a> Klaus, <em>Modrá</em>, 17.</p>
        <p><a href="#_ednref82" id="_edn82">[82]</a> Na tom istom mieste, 22.</p>
      <Link to={`/kapitola_17`} className="learn__more-btn">
        <span className="hyphen"/>
        <span className="btn_text">ďalšia kapitola</span>
      </Link>
      <Link to={`/`} className="learn__more-btn">
        <span className="hyphen"/>
        <span className="btn_text">späť na hlavnú stránku</span>
      </Link>
    </ContentPageWrapper>
  );
};


